import { Box, MediaQuery, Pagination, PaginationDropdown } from '@cmg/common';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ViewingOfferingsMessage from '../../../../../features/calendar/components/ViewingOfferingsMessage';
import TableSettingsDropdown from './TableSettingsDropdown';
import { SDataTable } from './WidgetWrapper.styles';

class WidgetWrapper extends Component {
  static propTypes = {
    activePage: PropTypes.number,
    activeTab: PropTypes.object,
    className: PropTypes.string,
    downloadExport: PropTypes.func,
    downloadDropdown: PropTypes.node,
    fillViewport: PropTypes.bool,
    filtersCollapsed: PropTypes.bool,
    handleChangeItemsPerPage: PropTypes.func,
    handlePagination: PropTypes.func,
    children: PropTypes.node.isRequired,
    isHidingBoxHeader: PropTypes.bool,
    isHidingColumnToggle: PropTypes.bool,
    hidePaginationBottom: PropTypes.bool,
    hidePaginationTop: PropTypes.bool,
    itemsPerPage: PropTypes.number,
    itemsPerPageValues: PropTypes.array,
    onChangeTab: PropTypes.func,
    rows: PropTypes.array,
    simpleColumns: PropTypes.array.isRequired,
    categorizedColumns: PropTypes.object,
    groupedColumns: PropTypes.object,
    columnsToggler: PropTypes.func,
    tabs: PropTypes.array,
    title: PropTypes.node,
    toggleColumnVisibility: PropTypes.func.isRequired,
    totalPages: PropTypes.number,
    screen: PropTypes.string,
    large: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    staticColumns: PropTypes.arrayOf(PropTypes.string),
    onResetFilters: PropTypes.func,
    viewingCurrent: PropTypes.number,
    viewingTotal: PropTypes.number,
  };

  componentDidMount() {
    if (this.props.fillViewport && !this.props.isMobile) {
      this.setDataTableHeight();
      window.addEventListener('resize', this.setDataTableHeight);
    }
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;
    const { fillViewport, filtersCollapsed } = prevProps;

    if (fillViewport && !this.props.isMobile && filtersCollapsed !== nextProps.filtersCollapsed) {
      setTimeout(() => {
        this.setDataTableHeight();
      }, 200);
    }
  }

  componentWillUnmount() {
    if (this.props.fillViewport && !this.props.isMobile) {
      window.removeEventListener('resize', this.setDataTableHeight);
    }
  }

  setDataTableHeight = debounce(() => {
    if (!this.dataTable) {
      return;
    }

    const rect = this.dataTable.getBoundingClientRect();
    const recalculate = rect.bottom !== window.innerHeight - 14;
    if (recalculate) {
      this.dataTable.style.height = `${window.innerHeight - rect.top - 14}px`;
    }
  }, 150);

  handleTabChanged = activeTab => {
    const { onChangeTab, handlePagination } = this.props;

    if (onChangeTab) {
      const p = onChangeTab(activeTab);
      p && p.then && p.then(() => handlePagination && handlePagination(1));
    }
  };

  renderBoxHeader() {
    const {
      rows,
      title,
      tabs,
      activeTab,
      downloadExport,
      downloadDropdown,
      simpleColumns,
      toggleColumnVisibility,
      totalPages,
      activePage,
      itemsPerPage,
      handleChangeItemsPerPage,
      handlePagination,
      isHidingColumnToggle,
      hidePaginationTop,
      screen,
      staticColumns,
      categorizedColumns,
      groupedColumns,
      columnsToggler,
      onResetFilters,
      viewingCurrent,
      viewingTotal,
    } = this.props;

    return (
      <Box.Header
        title={title}
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={this.handleTabChanged}
      >
        {!title && (
          <ViewingOfferingsMessage
            viewingCurrent={viewingCurrent}
            viewingTotal={viewingTotal}
            onResetFilters={onResetFilters}
          />
        )}

        <TableSettingsDropdown
          activeTab={activeTab}
          columns={simpleColumns}
          staticColumns={staticColumns}
          categorizedColumns={categorizedColumns}
          groupedColumns={groupedColumns}
          columnsToggler={columnsToggler}
          toggleColumnVisibility={toggleColumnVisibility}
          downloadExport={downloadExport}
          downloadDropdown={downloadDropdown}
          isHidingColumnToggle={isHidingColumnToggle}
          screen={screen}
        />

        {rows && rows.length !== 0 && !hidePaginationTop && (
          <PaginationDropdown itemsPerPage={itemsPerPage} onChange={handleChangeItemsPerPage} />
        )}

        {!!totalPages && !hidePaginationTop && (
          <Pagination
            totalPages={totalPages}
            activePage={activePage}
            onChangePage={handlePagination}
          />
        )}
      </Box.Header>
    );
  }

  renderBoxFooter() {
    const { rows, totalPages, activePage, handlePagination, hidePaginationBottom } = this.props;

    if (!rows || !rows.length || hidePaginationBottom) {
      return null;
    }

    return (
      <Box.Footer>
        {!!totalPages && (
          <Pagination
            totalPages={totalPages}
            activePage={activePage}
            onChangePage={handlePagination}
          />
        )}
      </Box.Footer>
    );
  }

  render() {
    const { fillViewport, children, isHidingBoxHeader, large, className, isMobile } = this.props;

    const cN = classnames('datatable', className);

    return (
      <SDataTable
        fillViewport={fillViewport && !isMobile}
        className={cN}
        ref={c => (this.dataTable = c)}
      >
        {!isHidingBoxHeader && this.renderBoxHeader()}

        {fillViewport && !isMobile ? children : <Box.Content large={large}>{children}</Box.Content>}

        {this.renderBoxFooter()}
      </SDataTable>
    );
  }
}

const WidgetWrapperFc = props => (
  <MediaQuery.IsMediumUp>
    {matches => <WidgetWrapper {...props} isMobile={!matches} />}
  </MediaQuery.IsMediumUp>
);

export default WidgetWrapperFc;
