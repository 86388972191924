import { checkPermissions, getUserPermissions, permissionsByEntity } from '@cmg/auth';
import { apiTypes, mixpanelUtil, Option } from '@cmg/common';
import { combineReducers } from 'redux';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  fetchAdvisorOptions,
  fetchFunds,
  fetchOfferingHeader,
  Fund,
  OfferingHeader,
} from '../../../api/dlgw';
import {
  createActionType,
  createApiActionCreators,
  createReducer,
  FAILURE,
  REQUEST,
  SUCCESS,
} from '../../../common/redux/reduxHelpers';

const { createMixPanelAction } = mixpanelUtil;

/**
 * ACTION TYPES
 */
const FETCH_OFFERING = 'offering-dl/FETCH_OFFERING';
export const FETCH_DATALAB_OPTIONS = 'offering-profile-dl/FETCH_DATALAB_OPTIONS';

/**
 * ACTIONS
 */
export const fetchOfferingActions = createApiActionCreators(FETCH_OFFERING);
export const fetchDatalabOptionsActions = createApiActionCreators(FETCH_DATALAB_OPTIONS);

type OfferingSharedState = {
  error: apiTypes.GenericServerError | null;
  offering: OfferingHeader | null;
  advisoryOptions: Option[];
  fundOptions: Fund[];
};

/**
 * REDUCERS
 */
export const initialState: OfferingSharedState = {
  error: null,
  offering: null,
  advisoryOptions: [],
  fundOptions: [],
};

const offering = createReducer(initialState.offering, {
  [FETCH_OFFERING]: {
    [SUCCESS]: (state, payload) => payload,
  },
});
const advisoryOptions = createReducer(initialState.advisoryOptions, {
  [FETCH_DATALAB_OPTIONS]: {
    [SUCCESS]: (state, payload) => payload.advisoryOptions,
  },
});
const fundOptions = createReducer(initialState.fundOptions, {
  [FETCH_DATALAB_OPTIONS]: {
    [SUCCESS]: (state, payload) => payload.fundOptions,
  },
});

const error = createReducer(initialState.error, {
  [FETCH_OFFERING]: {
    [SUCCESS]: () => null,
    [FAILURE]: (state, { error }) => error,
  },
});

export default combineReducers({
  offering,
  advisoryOptions,
  fundOptions,
  error,
});

/**
 * SELECTORS
 */
const selectOfferingDl = (state): OfferingSharedState => state.offeringDl;

export const selectOffering = (state): OfferingHeader | null => selectOfferingDl(state).offering;
export const selectAdvisoryOptions = (state): Option[] => selectOfferingDl(state).advisoryOptions;
export const selectFundOptions = (state): Fund[] => selectOfferingDl(state).fundOptions;
export const selectError = (state): apiTypes.GenericServerError | null =>
  selectOfferingDl(state).error;

/**
 * SAGAS
 */
function* fetchOffering({ payload }: any) {
  yield put(createMixPanelAction(FETCH_OFFERING, 'Offering Header'));
  const resp = yield call(fetchOfferingHeader, { id: payload.offeringId });

  if (resp.ok) {
    yield put(fetchOfferingActions.success(resp.data.data));
  } else {
    yield put(fetchOfferingActions.failure(resp.data));
  }
}

export function* fetchDatalabOptions() {
  yield put(createMixPanelAction(FETCH_DATALAB_OPTIONS, 'Fetch Datalab filter options'));

  const userPermissions = getUserPermissions();
  const canFetchFunds = checkPermissions(userPermissions, [permissionsByEntity.FundIoi.READ]);

  const [advisory, fund] = yield [
    call(fetchAdvisorOptions),
    canFetchFunds ? call(fetchFunds) : { ok: true, data: [] },
  ];

  if (advisory.ok && fund.ok) {
    const result = {
      advisoryOptions: advisory.data,
      fundOptions: fund.data,
    };
    yield put(fetchDatalabOptionsActions.success(result));
  }
}

export function* offeringDlSaga() {
  yield takeLatest(createActionType(FETCH_OFFERING, REQUEST), fetchOffering);
  yield takeEvery(createActionType(FETCH_DATALAB_OPTIONS, REQUEST), fetchDatalabOptions);
}
