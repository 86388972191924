import { checkPermissions, permissionsByEntity, withAuth } from '@cmg/auth';
import { BoxButton, Checkbox, Icon, Popover } from '@cmg/common';
import { ecmSelectors } from '@cmg/e2e-selectors';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { DatalabScreens } from '../../../../../features/datalab/constants';
import { ColumnsToggler } from './ColumnsToggler';
import {
  SDownloadHeader,
  SDownloadItem,
  SDownloadItemLabel,
  SDownloadMenu,
  SDownloadSubHeader,
  STableSettingsDropdown,
  StyledDownloadButton,
} from './TableSettingsDropdown.styles';

// TODO refactor to work with new DLGW - This conditional should be done at the dl route level and passed in, not in here
const getVisibilities = screen => {
  const onlyOfferingsAllowed = [
    DatalabScreens.IOI_LEAGUE_TABLE,
    DatalabScreens.FUND_IOI_LEAGUE_TABLE,
    DatalabScreens.LEAGUE_TABLE,
    DatalabScreens.SPONSOR_LEAGUE_TABLE,
    DatalabScreens.CAPITAL_RAISED_MARKET,
    DatalabScreens.AFTERMARKET_MATRIX,
    DatalabScreens.PL_LEAGUE_TABLE,
  ].includes(screen);
  const advisorsAllowed = [
    DatalabScreens.MARKET_PULSE,
    DatalabScreens.IPO_STRUCTURE,
    DatalabScreens.IPO_PRICING,
    DatalabScreens.FOLLOW_ON_STRUCTURE,
    DatalabScreens.FOLLOW_ON_PRICING,
    DatalabScreens.UNDERWRITING_TERMS,
    DatalabScreens.UNDERWRITER_OFFERINGS,
    DatalabScreens.UNDERWRITER_PROFILE_SELL,
    DatalabScreens.UNDERWRITER_PROFILE_BUY,
    DatalabScreens.AFTERMARKET_UNDERWRITER_OFFERINGS,
    DatalabScreens.LOCK_UP_EXPIRATION,
    DatalabScreens.BROKER_PL_OFFERINGS,
    DatalabScreens.IOI_PARTICIPATION,
    DatalabScreens.FUND_IOI_PARTICIPATION,
  ].includes(screen);
  const underwritersAndSponsorsAllowed = [
    DatalabScreens.MARKET_PULSE,
    DatalabScreens.IPO_STRUCTURE,
    DatalabScreens.IPO_PRICING,
    DatalabScreens.FOLLOW_ON_STRUCTURE,
    DatalabScreens.FOLLOW_ON_PRICING,
    DatalabScreens.UNDERWRITING_TERMS,
    DatalabScreens.UNDERWRITER_OFFERINGS,
    DatalabScreens.UNDERWRITER_PROFILE_SELL,
    DatalabScreens.UNDERWRITER_PROFILE_BUY,
    DatalabScreens.AFTERMARKET_UNDERWRITER_OFFERINGS,
    DatalabScreens.LOCK_UP_EXPIRATION,
    DatalabScreens.BROKER_PL_OFFERINGS,
    DatalabScreens.IOI_PARTICIPATION,
    DatalabScreens.FUND_IOI_PARTICIPATION,
  ].includes(screen);
  const ioiAndOfferingNotesDownloadAllowed = [
    DatalabScreens.IOI_PARTICIPATION,
    'calendar',
    DatalabScreens.FUND_IOI_PARTICIPATION,
    DatalabScreens.BROKER_PL_OFFERINGS,
  ].includes(screen);

  return {
    advisorsAllowed,
    onlyOfferingsAllowed,
    underwritersAndSponsorsAllowed,
    ioiAndOfferingNotesDownloadAllowed,
  };
};

const dwnCheckboxes = {
  includeAdvisors: {
    id: 'includeAdvisors',
    label: 'Advisors',
  },
  includeOfferingNotes: {
    id: 'includeOfferingNotes',
    label: 'Offering Notes',
  },
  includeIoiNotes: {
    id: 'includeIoiNotes',
    label: 'IOI Notes',
  },
  includeUnderwriters: {
    id: 'includeUnderwriters',
    label: 'Underwriters',
  },
  includeSponsors: {
    id: 'includeSponsors',
    label: 'Sponsors',
  },
  includeFundIoi: {
    id: 'includeFundIoi',
    label: 'Fund IOIs',
  },
};

// TODO typescript
const TableSettingsDropdown = ({
  activeTab,
  columns,
  staticColumns,
  downloadExport,
  downloadDropdown,
  isHidingColumnToggle,
  toggleColumnVisibility,
  categorizedColumns,
  groupedColumns,
  columnsToggler,
  screen,
}) => {
  const ColumnsTogglerComponent = columnsToggler || ColumnsToggler;

  return (
    <STableSettingsDropdown>
      {isHidingColumnToggle !== true && (
        <ColumnsTogglerComponent
          columns={columns}
          staticColumns={staticColumns}
          toggleColumnVisibility={toggleColumnVisibility}
          categorizedColumns={categorizedColumns}
          groupedColumns={groupedColumns}
        />
      )}
      {downloadDropdown}
      {!downloadDropdown && downloadExport && (
        <Download
          activeTab={activeTab}
          downloadExport={downloadExport}
          columns={columns}
          screen={screen}
        />
      )}
    </STableSettingsDropdown>
  );
};

TableSettingsDropdown.propTypes = {
  activeTab: PropTypes.object,
  columns: PropTypes.array.isRequired,
  staticColumns: PropTypes.arrayOf(PropTypes.string),
  toggleColumnVisibility: PropTypes.func.isRequired,
  downloadExport: PropTypes.func,
  /**
   * When supplied, displays this node, rather than the legacy <Download> component in this file
   * TODO - when we remove legacy datalab, downloadExport should be removed along with the <Download> component, only using this new prop.
   */
  downloadDropdown: PropTypes.node,
  isHidingColumnToggle: PropTypes.bool,
  screen: PropTypes.string,
  categorizedColumns: PropTypes.object,
  groupedColumns: PropTypes.object,
};

export default TableSettingsDropdown;

const initDownloadState = {
  includeAdvisors: false,
  includeOfferingNotes: false,
  includeIoiNotes: false,
  includeUnderwriters: false,
  includeSponsors: false,
  includeFundIoi: false,
  visible: false,
};

/**
 * @deprecated - When legacy datalab is removed, this should be removed as well. The new paradigm
 *  is to pass in 'downloadDropdown' node prop.
 */
class DownloadComponent extends Component {
  static propTypes = {
    activeTab: PropTypes.object,
    columns: PropTypes.array.isRequired,
    downloadExport: PropTypes.func,
    screen: PropTypes.string,
    userPermissions: PropTypes.array,
  };

  state = initDownloadState;

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  hide = () => this.setState(initDownloadState);

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  handleDownloadClick = () => {
    const { activeTab } = this.props;
    const { columns, downloadExport } = this.props;
    const visibleCheckboxes = this.getVisibleCheckboxes();
    const checkedAndAvailable = visibleCheckboxes
      .filter(vc => this.state[vc.id] === true)
      .map(vc => vc.id);

    downloadExport({
      columns: columns.filter(c => c.visible),
      includeOfferingNotes: checkedAndAvailable.includes(dwnCheckboxes.includeOfferingNotes.id),
      includeIoiNotes: checkedAndAvailable.includes(dwnCheckboxes.includeIoiNotes.id),
      includeUnderwriters: checkedAndAvailable.includes(dwnCheckboxes.includeUnderwriters.id),
      includeSponsors: checkedAndAvailable.includes(dwnCheckboxes.includeSponsors.id),
      includeFundIoi: checkedAndAvailable.includes(dwnCheckboxes.includeFundIoi.id),
      includeAdvisors: checkedAndAvailable.includes(dwnCheckboxes.includeAdvisors.id),
      screen: activeTab?.value,
    });
  };

  onClick = () => {
    this.handleDownloadClick();
    this.hide();
  };

  getVisibleCheckboxes = () => {
    const { screen, userPermissions } = this.props;
    const visibility = getVisibilities(screen);
    const checkboxes = [];

    if (visibility.advisorsAllowed) {
      checkboxes.push(dwnCheckboxes.includeAdvisors);
    }
    if (!visibility.onlyOfferingsAllowed) {
      checkboxes.push(dwnCheckboxes.includeOfferingNotes);
    }
    if (
      checkPermissions(userPermissions, [permissionsByEntity.Ioi.READ]) &&
      visibility.ioiAndOfferingNotesDownloadAllowed
    ) {
      checkboxes.push(dwnCheckboxes.includeIoiNotes);
    }
    if (visibility.underwritersAndSponsorsAllowed) {
      checkboxes.push(dwnCheckboxes.includeUnderwriters);
      checkboxes.push(dwnCheckboxes.includeSponsors);
    }
    if (
      checkPermissions(userPermissions, [permissionsByEntity.FundIoi.READ]) &&
      visibility.ioiAndOfferingNotesDownloadAllowed
    ) {
      checkboxes.push(dwnCheckboxes.includeFundIoi);
    }

    return checkboxes;
  };

  render() {
    const visibleCheckboxes = this.getVisibleCheckboxes();
    const dropdownDownload = (
      <SDownloadMenu>
        <SDownloadHeader>Download Offering Terms</SDownloadHeader>
        {visibleCheckboxes.length > 0 && (
          <React.Fragment>
            <SDownloadSubHeader> Also Include:</SDownloadSubHeader>
            {visibleCheckboxes.map(({ id, label }) => (
              <SDownloadItem key={id}>
                <Checkbox
                  id={id}
                  value={this.state[id]}
                  onChange={checked => {
                    this.setState({ [id]: checked });
                  }}
                />
                <SDownloadItemLabel htmlFor={id}>{label}</SDownloadItemLabel>
              </SDownloadItem>
            ))}
          </React.Fragment>
        )}
        <StyledDownloadButton variant="high-contrast" onClick={this.onClick}>
          Download
        </StyledDownloadButton>
      </SDownloadMenu>
    );

    return (
      <div>
        <Popover
          variant="DARK"
          placement="bottomLeft"
          content={dropdownDownload}
          trigger="click"
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
        >
          <BoxButton testId={ecmSelectors.oldGrid.toolbarDownloadButton.testId}>
            <Icon name="file-excel" />
          </BoxButton>
        </Popover>
      </div>
    );
  }
}

const Download = withAuth(DownloadComponent);
