import { numericUtil } from '@cmg/common';
import { Grid, Stack, Tooltip, Typography } from '@cmg/design-system';
import isNumber from 'lodash/isNumber';
import React from 'react';

import { OfferingProfile_Terms_PartFragment } from '../../../graphql/__generated__/OfferingProfile';

export type Props = {
  term?: OfferingProfile_Terms_PartFragment | null;
};
const OvltAuthTooltip: React.FC<Props> = ({ term }) => {
  if (!term || !isNumber(term.overAllotmentAuthorized)) {
    return <Typography>-</Typography>;
  }
  const {
    overAllotmentAuthorized,
    overAllotmentAuthorizedPrimary,
    overAllotmentAuthorizedSecondary,
  } = term;

  return overAllotmentAuthorized > 0 ? (
    <Tooltip
      title={
        <Stack gap={1}>
          <Grid container gap={1}>
            <Typography variant="tooltip" width={theme => theme.spacing(22.125)}>
              Over-allotment Authorized Primary
            </Typography>
            <Typography variant="tooltip" width={theme => theme.spacing(7.75)} align="right">
              {numericUtil.getDisplayValueForInteger(overAllotmentAuthorizedPrimary)}
            </Typography>
          </Grid>
          <Grid container gap={1}>
            <Typography variant="tooltip" width={theme => theme.spacing(22.125)}>
              Over-allotment Authorized Secondary
            </Typography>
            <Typography variant="tooltip" width={theme => theme.spacing(7.75)} align="right">
              {numericUtil.getDisplayValueForInteger(overAllotmentAuthorizedSecondary)}
            </Typography>
          </Grid>
          <Grid container gap={1}>
            <Typography variant="tooltip" width={theme => theme.spacing(22.125)}>
              Over-allotment Authorized
            </Typography>
            <Typography variant="tooltip" width={theme => theme.spacing(7.75)} align="right">
              {numericUtil.getDisplayValueForInteger(overAllotmentAuthorized)}
            </Typography>
          </Grid>
        </Stack>
      }
      placement="top-start"
      variant="info"
    >
      <Typography variant="hint">
        {numericUtil.getDisplayValueForInteger(overAllotmentAuthorized)}
      </Typography>
    </Tooltip>
  ) : (
    <Typography>{overAllotmentAuthorized}</Typography>
  );
};

export default OvltAuthTooltip;
