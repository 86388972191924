import { permissionsByEntity, useCheckPermissions } from '@cmg/auth';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isInternationalOfferingsOn } from '../../datalab/model/utils';
import { filterChange, initFilter, selectFilterOptions, selectFilterValues } from '../ducks';
import type { FilterValues } from '../model/calendar-filters';
import CalendarFilterForm from './CalendarFilterForm';

export type Props = {
  onFilterChange?: (values: FilterValues) => void;
};

export const CalendarFilter = ({ onFilterChange }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initFilter());
  }, [dispatch]);

  const filterValues = useSelector(selectFilterValues);
  const filterOptions = useSelector(selectFilterOptions);

  const showInternational = isInternationalOfferingsOn();
  const canReadCustomSectors = useCheckPermissions([permissionsByEntity.CustomSectors.READ]);

  return (
    <Formik<FilterValues>
      initialValues={filterValues}
      enableReinitialize
      onSubmit={values => {
        dispatch(filterChange(values));
        if (onFilterChange) {
          onFilterChange(values);
        }
      }}
    >
      {({ values: { useCustomSectors }, handleSubmit, setFieldValue }) => (
        <CalendarFilterForm
          showInternational={showInternational}
          filterOptions={filterOptions}
          useCustomSectors={canReadCustomSectors ? useCustomSectors : false}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
        />
      )}
    </Formik>
  );
};

export default CalendarFilter;
