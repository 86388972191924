import { Option } from '@cmg/common';

import { FirmSubType } from '../../../types/domain/firm/firmSubType';

enum ManagerRole {
  ACTIVE_BOOKRUNNER = 'ACTIVE_BOOKRUNNER',
  BOOKRUNNER = 'BOOKRUNNER', // only in DL
  CO_LEAD = 'CO_LEAD',
  CO_MANAGER = 'CO_MANAGER',
  UNDERWRITER = 'UNDERWRITER',
  SELLING_GROUP_MEMBER = 'SELLING_GROUP_MEMBER',
  GLOBAL_COORDINATOR = 'GLOBAL_COORDINATOR',
}

export const managerRoleLabels = {
  [ManagerRole.ACTIVE_BOOKRUNNER]: 'Active Bookrunner',
  [ManagerRole.BOOKRUNNER]: 'Bookrunner',
  [ManagerRole.CO_LEAD]: 'Co-Lead Manager',
  [ManagerRole.CO_MANAGER]: 'Co-Manager',
  [ManagerRole.UNDERWRITER]: 'Underwriter',
  [ManagerRole.SELLING_GROUP_MEMBER]: 'Selling Group Member',
  [ManagerRole.GLOBAL_COORDINATOR]: 'Global Coordinator',
};

export const managerRolesOptions: Option[] = [
  {
    value: ManagerRole.ACTIVE_BOOKRUNNER,
    label: managerRoleLabels[ManagerRole.ACTIVE_BOOKRUNNER],
  },
  {
    value: ManagerRole.BOOKRUNNER,
    label: managerRoleLabels[ManagerRole.BOOKRUNNER],
  },
  {
    value: ManagerRole.CO_LEAD,
    label: managerRoleLabels[ManagerRole.CO_LEAD],
  },
  {
    value: ManagerRole.CO_MANAGER,
    label: managerRoleLabels[ManagerRole.CO_MANAGER],
  },
  {
    value: ManagerRole.UNDERWRITER,
    label: managerRoleLabels[ManagerRole.UNDERWRITER],
  },
  {
    value: ManagerRole.SELLING_GROUP_MEMBER,
    label: managerRoleLabels[ManagerRole.SELLING_GROUP_MEMBER],
  },
  {
    value: ManagerRole.GLOBAL_COORDINATOR,
    label: managerRoleLabels[ManagerRole.GLOBAL_COORDINATOR],
  },
];

export enum SponsorType {
  CORPORATE = 'CORPORATE',
  INSIDER = 'INSIDER',
  NON_INSIDER = 'NON_INSIDER',
  PE = 'PE',
  VC = 'VC',
  OTHER_INSTITUTIONAL = 'OTHER_INSTITUTIONAL',
}

export const sponsorTypeLabels = {
  [SponsorType.CORPORATE]: 'Corporate',
  [SponsorType.INSIDER]: 'Insider',
  [SponsorType.NON_INSIDER]: 'Non-Insider',
  [SponsorType.PE]: 'PE',
  [SponsorType.VC]: 'VC',
  [SponsorType.OTHER_INSTITUTIONAL]: 'Other',
};

export declare enum SpacsOfferingTypes {
  IPO_SPACS = 'IPO_SPACS',
}

export enum ExtendedOfferingTypes {
  IPO = 'IPO',
  FO = 'FO',
  MARKETED_FO = 'MARKETED_FO',
  OVERNIGHT_FO = 'OVERNIGHT_FO',
  REGISTERED_BLOCK = 'REGISTERED_BLOCK',
  UNREGISTERED_BLOCK = 'UNREGISTERED_BLOCK',
  IPO_SPACS = 'IPO_SPACS',
  RIGHTS = 'RIGHTS',
  REGISTERED_DIRECT = 'REGISTERED_DIRECT',
  DIRECT_LISTING = 'DIRECT_LISTING',
  ABB_ABO = 'ABB_ABO',
  BLOCK = 'BLOCK',
  FULLY_MARKETED_OFFERING = 'FULLY_MARKETED_OFFERING',
}

export const OfferingOptions = {
  [ExtendedOfferingTypes.IPO]: { value: 'IPO', label: 'IPO' },
  [ExtendedOfferingTypes.RIGHTS]: { value: 'RIGHTS', label: 'Rights' },
  [ExtendedOfferingTypes.OVERNIGHT_FO]: { value: 'OVERNIGHT_FO', label: 'Overnight FO' },
  [ExtendedOfferingTypes.MARKETED_FO]: { value: 'MARKETED_FO', label: 'Marketed FO' },
  [ExtendedOfferingTypes.REGISTERED_BLOCK]: {
    value: 'REGISTERED_BLOCK',
    label: 'Registered Block',
  },
  [ExtendedOfferingTypes.UNREGISTERED_BLOCK]: {
    value: 'UNREGISTERED_BLOCK',
    label: 'Unregistered Block',
  },
  [ExtendedOfferingTypes.IPO_SPACS]: {
    label: 'IPO (SPACs)',
    value: 'IPO_SPACS',
  },
  [ExtendedOfferingTypes.REGISTERED_DIRECT]: {
    label: 'Registered direct',
    value: 'REGISTERED_DIRECT',
  },
  [ExtendedOfferingTypes.DIRECT_LISTING]: {
    label: 'Direct listing',
    value: 'DIRECT_LISTING',
  },
  [ExtendedOfferingTypes.ABB_ABO]: { value: 'ABB_ABO', label: 'ABB/ABO' },
  [ExtendedOfferingTypes.BLOCK]: { value: 'BLOCK', label: 'Block' },
  [ExtendedOfferingTypes.FULLY_MARKETED_OFFERING]: {
    value: 'FULLY_MARKETED_OFFERING',
    label: 'Fully Marketed Offering',
  },
};

export const ipoSpacsOption = {
  label: 'IPO (SPACs)',
  value: 'IPO_SPACS',
};

// TECH: This options needs to be removed
export const ipoOfferingTypeOptions = [{ value: 'IPO', label: 'IPO' }];

export const followOnOfferingTypeOptions = [
  { value: 'MARKETED_FO', label: 'Marketed FO' },
  { value: 'OVERNIGHT_FO', label: 'Overnight FO' },
  { value: 'REGISTERED_BLOCK', label: 'Registered Block' },
  { value: 'UNREGISTERED_BLOCK', label: 'Unregistered Block' },
];

export const allOption: Option = {
  value: 'ALL',
  label: 'All',
  clearableValue: false,
};

export enum TableDataManagementType {
  SERVER = 'SERVER',
  CLIENT = 'CLIENT',
}

export enum FilterType {
  ONE_VALUE = 'oneValue',
  MORE_VALUES_OR = 'moreValuesOR',
  FILTER_BY_VALUE_IN_LIST = 'filterByValueInList',
  SEARCH_TEXT = 'searchText',
}

/**
 * Keyboard keys
 */
export enum KEYS {
  ENTER = 'Enter',
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
}

export const firmSubTypeLabels: { [key in FirmSubType]: string } = {
  CORPORATE: 'Corporate',
  INDIVIDUAL: 'Individual',
  PRIVATE_EQUITY: 'PE',
  VENTURE_CAPITAL: 'VC',
  OTHER_INSTITUTIONAL: 'Other',
};
