import {
  makeParameterlessFetchEntityRequest,
  makeParameterlessUpdateEntityRequest,
} from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';
import { UserSettings } from './types/UserSettings';

export type UserSettingsPayload = { settings: UserSettings };

export const fetchUserSettings = makeParameterlessFetchEntityRequest<UserSettingsPayload>(
  dlgwApiClient,
  '/myUserSettings'
);

export const updateUserSettings = makeParameterlessUpdateEntityRequest<
  UserSettingsPayload,
  UserSettingsPayload
>(dlgwApiClient, '/myUserSettings');
