import { permissionsByEntity, useAuth, useCheckPermissions } from '@cmg/auth';

import { OfferingType } from '../../../graphql/__generated__';
import { CalendarCategory } from '../../../types/domain/calendar/constants';
import { useCalendar_OfferingListQuery } from '../graphql/__generated__/Calendar_OfferingList';
import {
  type CalendarOfferingType,
  type FilterValues,
  InternalOfferingType,
} from '../model/calendar-filters';
import { getGraphqlWhere } from './graphql-query';

// Define which offering types to include in the filter
const includedOfferingTypes: CalendarOfferingType[] = [
  OfferingType.Ipo,
  InternalOfferingType.IPO_SPACS,
  OfferingType.FollowOn,
  OfferingType.MarketedFo,
  OfferingType.OvernightFo,
  OfferingType.Rights,
  OfferingType.RegisteredBlock,
  OfferingType.UnregisteredBlock,
  OfferingType.Convertible,
  OfferingType.RegisteredDirect,
  OfferingType.DirectListing,
];

const getEmptyFilterValues = (): FilterValues => ({
  offeringType: includedOfferingTypes,
  sector: [],
  customSectorId: [],
  sizeInDollars: { min: undefined, max: undefined },
  marketCap: { min: undefined, max: undefined },
  leftLeadFirmId: [],
  managerFirmId: [],
  useCustomSectors: false,
  countries: [],
});

export type TotalOfferingsQueryResult = {
  totalCount: number;
};

export type TotalOfferingsParams = {
  calendarCategory: CalendarCategory;
};

export default function useTotalOfferingsQuery({ calendarCategory }: TotalOfferingsParams) {
  const { oidcUserId, userPermissions } = useAuth();

  const queryResult = useCalendar_OfferingListQuery({
    variables: {
      skip: 0, // Skip is 0 since we are only interested in the total count, not paginated data.
      take: 1, // Take 1 offering just to get the total count (this is a workaround to trigger the query).
      where: getGraphqlWhere({
        userId: oidcUserId!,
        calendarCategory,
        userPermissions,
        filters: getEmptyFilterValues(), // No filters applied for the total count query.
      }),
      hasIoiPermission: useCheckPermissions([permissionsByEntity.Ioi.READ]),
      hasCustomSectorsPermission: useCheckPermissions([permissionsByEntity.CustomSectors.READ]),
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const totalCount = queryResult.data?.offerings?.totalCount ?? 0;

  return {
    totalCount,
    loading: queryResult.loading,
    error: queryResult.error,
  };
}
