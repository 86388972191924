import { TableCell } from '@cmg/design-system';
import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';

import { CornerstoneInvestmentType, Currency } from '../../../graphql/__generated__';
import { getFormattedCurrencyInMillion } from '../../shared/model/utils';
import { type Calendar_Offering_CornerstoneInvestmentFragment } from '../graphql';
import { HeaderLabel } from './CornerstoneInvestorsRenderer.styles';

export const CornerstoneInvestorTypeLabels = {
  [CornerstoneInvestmentType.OfferingParticipant]: 'Offering Participants',
  [CornerstoneInvestmentType.PrivatePlacement]: 'Private Placement',
};

export type Props = {
  cornerstoneInvestments: Calendar_Offering_CornerstoneInvestmentFragment[];
  investmentType: CornerstoneInvestmentType;
  cornerstoneInvestorsCount?: number | null;
  cornerstoneOfferingAmount?: number | null;
  pricingCurrency?: Currency | null;
};

const renderIndividual = (
  investment: Calendar_Offering_CornerstoneInvestmentFragment,
  idx: number,
  pricingCurrency?: Currency | null
) => {
  const investors = investment.investors ?? [];
  let investorName = 'Not Disclosed'; // Default name when there are no investors

  // If there is an investor, use the actual investor's name
  if (investors.length > 0) {
    const investor = investors[0];
    investorName = investor.shareholder?.name ?? 'Not Disclosed';
  }

  return (
    <tr className="individual-investment" key={idx}>
      <td>{investorName}</td>
      <td>{investors.length > 0 ? investors[0].shareholder?.shareholderTypeDisplayName : 'N/A'}</td>
      <td>{investors.length > 0 && investors[0].isExistingCornerstoneInvestor ? 'No' : 'Yes'}</td>
      <td>{getFormattedCurrencyInMillion(pricingCurrency, investment.individualAmount)}</td>
    </tr>
  );
};

const renderCombined = (
  combinedInvestments: Calendar_Offering_CornerstoneInvestmentFragment,
  idx: number,
  pricingCurrency?: Currency | null
) => {
  const investors = combinedInvestments.investors ?? [];

  return (
    <React.Fragment key={idx}>
      <tr className="combined-amount-row">
        <td colSpan={3}>Combined Amount</td>
        <td>
          {getFormattedCurrencyInMillion(pricingCurrency, combinedInvestments.individualAmount)}
        </td>
      </tr>
      {investors.map((investor, idx) => (
        <tr className="combined-row" key={idx}>
          <td>{investor.shareholder?.name}</td>
          <td>{investor.shareholder?.shareholderTypeDisplayName}</td>
          <td>{investor.isExistingCornerstoneInvestor ? 'No' : 'Yes'}</td>
          <td />
        </tr>
      ))}
      <tr>
        <TableCell colSpan={4} style={{ borderBottomWidth: '2px' }} divider />
      </tr>
    </React.Fragment>
  );
};

const CornerstoneInvestorsTableContent: React.FC<Props> = ({
  cornerstoneInvestorsCount,
  cornerstoneOfferingAmount,
  cornerstoneInvestments,
  investmentType,
  pricingCurrency,
}) => {
  const individualInvestments = cornerstoneInvestments.filter(
    c => (c.investors?.length ?? 0) === 1 || (!c.investors?.length && !!c.individualAmount)
  );
  const combinedInvestments = cornerstoneInvestments.filter(c => (c.investors?.length ?? 0) > 1);

  const sortedIndividualInvestments = useMemo(() => {
    return orderBy(individualInvestments, 'individualAmount', 'desc');
  }, [individualInvestments]);

  let hasInsertedCombinedInvestment = false;

  return cornerstoneInvestments.length >= 1 ? (
    <React.Fragment>
      <thead>
        <tr>
          <HeaderLabel className="cornerstone-type">{`Cornerstone\u2013${CornerstoneInvestorTypeLabels[investmentType]}`}</HeaderLabel>
        </tr>
        <tr>
          <th style={{ width: '50%' }}>Investor</th>
          <th style={{ width: '15%' }}>Type</th>
          <th style={{ width: '19%' }}>New Investor</th>
          <th style={{ width: '16%' }}>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr className="total-row">
          <td colSpan={3}>{`Total ${cornerstoneInvestorsCount}`}</td>
          <td>{getFormattedCurrencyInMillion(pricingCurrency, cornerstoneOfferingAmount)}</td>
        </tr>

        {sortedIndividualInvestments.map((investment, idx) => {
          // Check if we should insert the combined investment row
          const shouldInsertCombinedInvestment =
            !hasInsertedCombinedInvestment &&
            combinedInvestments.length > 0 &&
            (investment.individualAmount ?? 0) <= (combinedInvestments[0].individualAmount ?? 0);

          // If we should insert the combined investment row, mark it as inserted
          if (shouldInsertCombinedInvestment) {
            hasInsertedCombinedInvestment = true;
          }

          return (
            <React.Fragment>
              {shouldInsertCombinedInvestment &&
                combinedInvestments.map((investment, idx) =>
                  renderCombined(investment, idx, pricingCurrency)
                )}

              {renderIndividual(investment, idx, pricingCurrency)}
            </React.Fragment>
          );
        })}
        {!hasInsertedCombinedInvestment &&
          combinedInvestments.length > 0 &&
          combinedInvestments.map((investment, idx) =>
            renderCombined(investment, idx, pricingCurrency)
          )}
      </tbody>
    </React.Fragment>
  ) : null;
};

export default CornerstoneInvestorsTableContent;
