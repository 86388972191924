import { AccountType, getUserAccountType } from '@cmg/auth';
import { duckPartFactory, mixpanelUtil } from '@cmg/common';
import camelCase from 'lodash/camelCase';
import isEqual from 'lodash/isEqual';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import * as dlgwApi from '../../../../api/dlgw';
import { RootState } from '../../../../common/redux/rootReducer';
import { BuySideDashboardDto } from '../../../../types/domain/my-dashboard/buySideDashboard';
import { BuySideDashboardParameters } from '../../../../types/domain/my-dashboard/myDashboardParameters';
import { selectUserSettingUseCustomSectors } from '../../../shared/ducks';
import * as DashboardControls from '../../dashboard/controls/ducks';

const { createMixPanelAction } = mixpanelUtil;

/**
 * processMixPanelControlsPayload flattens the date periods of the dashboard parameters
 * and adds flags for whether or not sectors/custom sectors were entered.
 *
 * This will allow us to filter by individual fields in mixpanel
 */
export const processMixPanelControlsPayload = (payload: BuySideDashboardParameters) => {
  const { timePeriod, comparisonPeriod, ...rest } = payload;
  const flatPeriod = (period, parentKey) =>
    Object.keys(period).reduce(
      (total, current) => ({
        ...total,
        [camelCase(`${parentKey} ${current}`)]: period[current],
      }),
      {}
    );
  return {
    ...rest, // payload excluding time periods
    ...flatPeriod(payload.timePeriod, 'timePeriod'),
    ...flatPeriod(payload.comparisonPeriod, 'comparisonPeriod'),
    hasSectors: payload.sectorCodes.length > 0,
    hasCustomSectors: payload.customSectorIds.length > 0,
  };
};

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  INIT_MY_DASHBOARD = 'MY_DASHBOARD/INIT_BUY_SIDE_DASHBOARD',
}

/**
 * DUCK PARTS DEFINITIONS
 */
export const {
  actionCreators: {
    request: fetchBuySideDashboardRequest,
    success: fetchBuySideDashboardSucceeded,
    failure: fetchBuySideDashboardFailed,
  },
  initialState: buySideDashboardInitialState,
  reducer: buySideDashboardReducer,
  actionTypes: { REQUEST: fetchBuySideDashboardRequestType },
  makeSelectors: makeBuySideDashboardSelectors,
} = duckPartFactory.makeAPIDuckParts<undefined, BuySideDashboardDto>({
  prefix: 'MY_DASHBOARD/FETCH_BUY_SIDE_DASHBOARD',
});

/**
 * ACTIONS
 */
export type FetchBuySideDashboardAction = ReturnType<typeof fetchBuySideDashboardRequest>;

/**
 * this action is triggered upon entering individual datalab screens
 * corresponding saga checks if filter values should be persisted or reset
 */
export const initMyDashboard = () => ({
  type: ActionTypes.INIT_MY_DASHBOARD,
});
export type InitMyDashboardAction = ReturnType<typeof initMyDashboard>;

/**
 * REDUCERS
 */

export type ReducerState = {
  /** buy side dashboard offerings */
  offerings: typeof buySideDashboardInitialState;
};

export const initialState: ReducerState = {
  offerings: buySideDashboardInitialState,
};

export const reducer = combineReducers<ReducerState>({
  offerings: buySideDashboardReducer,
});

export default reducer;

/**
 * Selectors
 */
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, (a, b) => isEqual(a, b));

export const selectMyDashboard = (state: RootState) => state.myDashboard;
export const selectBuySideDashboard = (state: RootState) =>
  selectMyDashboard(state).buySideDashboard;
/** BuySide Dashboard Offerings */
export const selectBuySideOfferings = (state: RootState) => selectBuySideDashboard(state).offerings;

export const selectAllControls = createDeepEqualSelector(
  (state: RootState) => ({
    ...DashboardControls.selectTopControls(state),
    timePeriod: DashboardControls.selectTimePeriodControl(state),
    comparisonPeriod: DashboardControls.selectComparisonPeriodControl(state),
  }),
  allControls => allControls as BuySideDashboardParameters
);

const buySideSelectors = makeBuySideDashboardSelectors(selectBuySideOfferings);
export const selectBuySideData = buySideSelectors.selectData;
export const selectBuySideLoading = buySideSelectors.selectLoading;
export const selectBuySideError = buySideSelectors.selectError;

/** Periods */
export const selectBuySideCurrentPeriod = (state: RootState) => selectBuySideData(state)?.current;

export const selectBuySideComparisonPeriod = (state: RootState) =>
  selectBuySideData(state)?.comparison;

/**  US Ecm */
export const selectBuySideCurrentUsEcm = state => selectBuySideCurrentPeriod(state)?.usEcm;

export const selectBuySideComparisonUsEcm = state => selectBuySideComparisonPeriod(state)?.usEcm;

/** My  Participation Summary */
export const selectBuySideCurrentMyParticipationSummary = state =>
  selectBuySideCurrentPeriod(state)?.myParticipationSummary;

export const selectBuySideComparisonMyParticipationSummary = state =>
  selectBuySideComparisonPeriod(state)?.myParticipationSummary;

/** Passed */
export const selectBuySideCurrentPassed = state => selectBuySideCurrentPeriod(state)?.passed;

export const selectBuySideComparisonPassed = state => selectBuySideComparisonPeriod(state)?.passed;

/** IPO Pricing  Summary */
export const selectBuySideCurrentIpoPricingSummary = state =>
  selectBuySideCurrentPeriod(state)?.ipoPricingSummary;

export const selectBuySideComparisonIpoPricingSummary = state =>
  selectBuySideComparisonPeriod(state)?.ipoPricingSummary;

/** Follow  On PricingSummary */
export const selectBuySideCurrentFollowOnPricingSummary = state =>
  selectBuySideCurrentPeriod(state)?.followOnPricingSummary;

export const selectBuySideComparisonFollowOnPricingSummary = state =>
  selectBuySideComparisonPeriod(state)?.followOnPricingSummary;

/**
 * SAGAS
 */
/** Fetch buy-side dashboard saga */
export function* fetchBuySideDashboardSaga(): SagaIterator {
  const accountType = getUserAccountType();
  if (accountType !== AccountType.BUY_SIDE) {
    return;
  }

  const allControls = yield select(selectAllControls);

  yield put(
    createMixPanelAction(
      fetchBuySideDashboardRequestType,
      'Fetch Buy-Side Dashboard Offerings',
      processMixPanelControlsPayload(allControls)
    )
  );

  const resp: dlgwApi.FetchBuySideDashboardResponse = yield call(
    dlgwApi.fetchBuySideDashboard,
    allControls
  );

  if (resp.ok) {
    yield put(fetchBuySideDashboardSucceeded(resp.data));
  } else {
    yield put(fetchBuySideDashboardFailed(resp.data.error));
  }
}

export function* controlsUpdatedSaga(): SagaIterator {
  yield put(fetchBuySideDashboardRequest(undefined));
}

/**
 * initMyDashboardSaga handles the initial requests that needs to be made
 * for the dashboard column of My Dashboard.
 */
export function* initMyDashboardSaga(action: InitMyDashboardAction): SagaIterator {
  const userSettingsUseCustomSectors = yield select(selectUserSettingUseCustomSectors);
  const useCustomSectors = yield select(DashboardControls.selectUseCustomSectors);

  // initialize the local useCustomSectors value to the global setting
  if (useCustomSectors !== userSettingsUseCustomSectors) {
    yield put(DashboardControls.setUseCustomSectors(userSettingsUseCustomSectors));
  }

  yield put(fetchBuySideDashboardRequest(undefined));
}

export function* buySideDashboardSaga() {
  yield takeLatest(fetchBuySideDashboardRequestType, fetchBuySideDashboardSaga);
  yield takeLatest(
    [
      DashboardControls.ActionTypes.TOP_CONTROLS_UPDATED,
      DashboardControls.ActionTypes.COMPARISON_PERIOD_CONTROL_UPDATED,
    ],
    controlsUpdatedSaga
  );
  yield takeEvery<InitMyDashboardAction>(ActionTypes.INIT_MY_DASHBOARD, initMyDashboardSaga);
}
