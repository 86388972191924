import { apiTypes } from '@cmg/common';
import type { AxiosError, AxiosResponse } from 'axios';

import { ErrorApiResponse, SuccessApiResponse } from '../../types/api/ApiResponse';
import { getInnerError } from './error';

export function normalizeSuccessResponse<T = any>(
  response: AxiosResponse<T>
): SuccessApiResponse<T> {
  return {
    ...response,
    ok: true,
  };
}

export function normalizeErrorResponse(
  error: AxiosError<{ error?: apiTypes.GenericServerError } | undefined | null>
): ErrorApiResponse {
  return {
    ...error,
    ok: false,
    data: { error: getInnerError(error) },
  };
}
