import {
  DLGWCornerstoneInvestmentRowItem,
  DLGWMarketPulseTableRow,
} from '@capital-markets-gateway/api-client-datalab-gateway';
import { TableCell } from '@cmg/design-system';
import orderBy from 'lodash/orderBy';
import React, { useMemo } from 'react';

import {
  CornerstoneInvestorType,
  CornerstoneInvestorTypeLabels,
  CornerstoneShareholderTypeLabels,
} from '../../../../../types/domain/offering/constants';
import { getFormattedCurrencyInMillion } from '../../../model/utils';
import { HeaderLabel } from './CornerstoneTooltip.styles';

type CurrencyCode = DLGWMarketPulseTableRow['pricing_currency_code'];
export type Props = {
  cornerstone_investments: DLGWCornerstoneInvestmentRowItem[];
  cornerstone_investors_offering_participants_count?: DLGWMarketPulseTableRow['cornerstone_investors_offering_participants_count'];
  cornerstone_offering_participants_amount?: DLGWMarketPulseTableRow['cornerstone_offering_participants_amount'];
  investment_type: CornerstoneInvestorType;
  pricing_currency_code: CurrencyCode;
};

const renderIndividual = (
  investment: DLGWCornerstoneInvestmentRowItem,
  idx: number,
  pricing_currency_code: CurrencyCode
) => {
  const investors = investment.investors ?? [];
  let investorName = 'Not Disclosed'; // Default name when there are no investors

  // If there is an investor, use the actual investor's name
  if (investors.length > 0) {
    const investor = investors[0];
    investorName = investor.shareholder?.firm_name ?? 'Not Disclosed';
  }

  return (
    <tr className="individual-investment" key={idx}>
      <td>{investorName}</td>
      <td>
        {investors.length > 0 && investors[0].shareholder?.sponsor_type
          ? CornerstoneShareholderTypeLabels[investors[0].shareholder?.sponsor_type]
          : 'N/A'}
      </td>
      <td>
        {investors.length > 0 && investors[0].is_existing_cornerstone_investor ? 'No' : 'Yes'}
      </td>
      <td>{getFormattedCurrencyInMillion(pricing_currency_code, investment.individual_amount)}</td>
    </tr>
  );
};

const renderCombined = (
  combinedInvestments: DLGWCornerstoneInvestmentRowItem,
  idx: number,
  pricing_currency_code: CurrencyCode
) => {
  const investors = combinedInvestments.investors ?? [];

  return (
    <React.Fragment key={idx}>
      <tr className="combined-amount-row">
        <td colSpan={3}>Combined Amount</td>
        <td>
          {getFormattedCurrencyInMillion(
            pricing_currency_code,
            combinedInvestments.individual_amount
          )}
        </td>
      </tr>
      {investors.map((investor, idx) => (
        <tr className="combined-row" key={idx}>
          <td>{investor.shareholder?.firm_name}</td>
          <td>
            {investor.shareholder?.sponsor_type
              ? CornerstoneShareholderTypeLabels[investor.shareholder?.sponsor_type]
              : ''}
          </td>
          <td>{investor.is_existing_cornerstone_investor ? 'No' : 'Yes'}</td>
          <td />
        </tr>
      ))}
      <tr>
        <TableCell colSpan={4} style={{ borderBottomWidth: '2px' }} divider />
      </tr>
    </React.Fragment>
  );
};

const CornerstoneTooltipTableContent: React.FC<Props> = ({
  cornerstone_investors_offering_participants_count,
  cornerstone_offering_participants_amount,
  cornerstone_investments,
  investment_type,
  pricing_currency_code,
}) => {
  const individualInvestments = cornerstone_investments.filter(
    c => (c.investors?.length ?? 0) === 1 || (!c.investors?.length && !!c.individual_amount)
  );
  const combinedInvestments = cornerstone_investments.filter(c => (c.investors?.length ?? 0) > 1);

  const sortedIndividualInvestments = useMemo(() => {
    return orderBy(individualInvestments, 'individualAmount', 'desc');
  }, [individualInvestments]);

  let hasInsertedCombinedInvestment = false;

  return cornerstone_investments.length >= 1 ? (
    <React.Fragment>
      <thead>
        <tr>
          <HeaderLabel className="cornerstone-type">{`Cornerstone\u2013${CornerstoneInvestorTypeLabels[investment_type]}`}</HeaderLabel>
        </tr>
        <tr>
          <th style={{ width: '50%' }}>Investor</th>
          <th style={{ width: '15%' }}>Type</th>
          <th style={{ width: '19%' }}>New Investor</th>
          <th style={{ width: '16%' }}>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr className="total-row">
          <td colSpan={3}>{`Total ${cornerstone_investors_offering_participants_count}`}</td>
          <td>
            {getFormattedCurrencyInMillion(
              pricing_currency_code,
              cornerstone_offering_participants_amount
            )}
          </td>
        </tr>

        {sortedIndividualInvestments.map((investment, idx) => {
          // Check if we should insert the combined investment row
          const shouldInsertCombinedInvestment =
            !hasInsertedCombinedInvestment &&
            combinedInvestments.length > 0 &&
            (investment.individual_amount ?? 0) <= (combinedInvestments[0].individual_amount ?? 0);

          // If we should insert the combined investment row, mark it as inserted
          if (shouldInsertCombinedInvestment) {
            hasInsertedCombinedInvestment = true;
          }

          return (
            <React.Fragment>
              {shouldInsertCombinedInvestment &&
                combinedInvestments.map((investment, idx) =>
                  renderCombined(investment, idx, pricing_currency_code)
                )}

              {renderIndividual(investment, idx, pricing_currency_code)}
            </React.Fragment>
          );
        })}

        {!hasInsertedCombinedInvestment &&
          combinedInvestments.length > 0 &&
          combinedInvestments.map((investment, idx) =>
            renderCombined(investment, idx, pricing_currency_code)
          )}
      </tbody>
    </React.Fragment>
  ) : null;
};

export default CornerstoneTooltipTableContent;
