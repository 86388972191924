import { urlUtil } from '@cmg/common';
import axios from 'axios';

import { getAppSettings } from '../../config/appSettings';

const appSettings = getAppSettings();
const accountSubdomain = urlUtil.getAccountSubdomain(window.location);
const baseURL = urlUtil.addSubdomainToUrl(appSettings.env.identityServiceBaseUrl, accountSubdomain);

export const identityApi = axios.create({
  baseURL: `${baseURL}api/v1`,
  responseType: 'json',
  headers: {
    'Client-App-Version': appSettings.client.appVersion,
    'Content-Type': 'application/json',
  },
});
