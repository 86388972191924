import { Box } from '@cmg/common';
import { widgetDailyVolume } from '@cmg/e2e-selectors';
import React, { useState } from 'react';

import { getFeatureToggles } from '../../../../../config/appSettings';
import Toggler from '../../../../shared/layout/components/Toggler';
import {
  heatmapBreakDownBy,
  heatmapDropoffColumns,
  rubyHeatmapBreakDownBy,
} from '../../../constants-heatmap-columns';
import heatMapDropOffButtons from '../../../model/buttons/heat-map-drop-off-buttons';
import HeatMap from '../../HeatMap';
import { SButtonBox } from '../shared/styled';

type Props = {
  chartData?: {
    items: any[];
    min_value: number;
    max_value: number;
  };
};

const WidgetVolumeDropoffBySector: React.FC<Props> = ({
  chartData = { items: [], min_value: 0, max_value: 0 },
}) => {
  const [heatMapDropoffBtn, setHeatMapDropoffBtn] = useState(heatMapDropOffButtons[0]);
  const { enableUseRestReports } = getFeatureToggles();

  const handleDropoffBtnChange = (btn: any) => {
    setHeatMapDropoffBtn(btn);
  };

  return (
    <div data-test-id={widgetDailyVolume.testId}>
      <Box.Header title="Daily Volume % of Deal Size" />

      <SButtonBox>
        <Toggler
          buttons={heatMapDropOffButtons}
          activeButton={heatMapDropoffBtn}
          onChange={handleDropoffBtnChange}
          size="small"
        />
      </SButtonBox>

      <Box.Content>
        <HeatMap
          columns={heatmapDropoffColumns}
          data={chartData}
          valueKey={heatMapDropoffBtn.value}
          breakDownBy={
            enableUseRestReports ? heatmapBreakDownBy.SECTOR : rubyHeatmapBreakDownBy.SECTOR
          }
        />
      </Box.Content>
    </div>
  );
};

export default WidgetVolumeDropoffBySector;
