import { Box, FormField, timeUtil } from '@cmg/common';
import React from 'react';
import styled from 'styled-components/macro';

import { IoiOffering } from '../../../../types/domain/offering/ioi/ioi';
import { UnderwriterCreditsOffering } from '../../../../types/domain/underwriter-credits/underwriterCreditsOffering';

const SRow = styled.div`
  display: flex;
  margin: 5px;
`;
const StyledFormField = styled(FormField)`
  width: 33%;
  min-width: auto;
`;

type Props = { offering: IoiOffering | UnderwriterCreditsOffering };

const OfferingOverviewGrid: React.FC<Props> = ({ offering }) => (
  <Box>
    <Box.Content>
      <SRow>
        <StyledFormField label="Company">
          <div>{offering.name}</div>
        </StyledFormField>
        <StyledFormField label="Offering Type">
          <div>{offering.offeringType}</div>
        </StyledFormField>
        <StyledFormField label="First Trade Date">
          <div>
            {offering.firstTradeDate && timeUtil.formatAsDisplayDate(offering.firstTradeDate)}
          </div>
        </StyledFormField>
      </SRow>
      <SRow>
        <StyledFormField label="Last Edited Time">
          <div>
            {offering.lastEditedTime && timeUtil.formatAsDisplayDateTime(offering.lastEditedTime)}
          </div>
        </StyledFormField>
        <StyledFormField label="Last Edited By (Name)">
          <div>{offering.lastEditedByName}</div>
        </StyledFormField>
        <StyledFormField label="Last Edited By (Email)">
          <div>{offering.lastEditedByEmail}</div>
        </StyledFormField>
      </SRow>
    </Box.Content>
  </Box>
);

export default OfferingOverviewGrid;
