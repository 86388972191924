import { useAuth } from '@cmg/auth';

import { checkIsFollowingOffering } from '../../shared/follow-offering/checkIsFollowingOffering';
import useSharedFollowOfferingMutation from '../../shared/follow-offering/useFollowOfferingMutation';
import { type Calendar_OfferingFieldsFragment } from '../graphql';

export type Params = { onCompleted?: () => void };

export default function useFollowOfferingMutation({ onCompleted }: Params = {}) {
  const { setFollowingOffering } = useSharedFollowOfferingMutation({ onCompleted });
  const { oidcUserId, userPermissions } = useAuth();

  const handleToggleFollowOffering = async (offering: Calendar_OfferingFieldsFragment) => {
    const isFollowing = checkIsFollowingOffering(offering, oidcUserId!, userPermissions);
    await setFollowingOffering(offering.id, !isFollowing);
  };

  return {
    handleToggleFollowOffering,
  };
}
