import { AsyncSelectField, Group, Icon, Option, timeUtil } from '@cmg/common';
import { seeAllReportsButtonSelector } from '@cmg/e2e-selectors';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';

import api from '../../../../api/dl-ruby/datalab-api';
import { UserReport } from '../../../../types/domain/report/userReport';
import { UserReportPartial } from '../../../../types/domain/report/userReportPartial';
import {
  selectDefaultReportSelectOptions,
  selectDefaultReportSelectOptionsLoading,
  selectReport,
} from '../../ducks';
import { ALL_REPORTS_MODAL_ID } from '../modals/AllReportsModal';
import {
  SGroupLabel,
  SOption,
  SOptionReportIcon,
  SOptionReportIcons,
  SOptionReportInfo,
  SOptionReportMeta,
  SOptionReportName,
  SStickyMenuListButton,
} from './DatalabReportSelectField.styles';

const mapStateToProps = (
  state
): {
  defaultOptions: {
    name: string;
    options: UserReportPartial[];
  }[];
  areDefaultOptionsLoading: boolean;
  report?: UserReport;
} => ({
  defaultOptions: selectDefaultReportSelectOptions(state),
  areDefaultOptionsLoading: selectDefaultReportSelectOptionsLoading(state),
  report: selectReport(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      onClickSeeAll: () => show(ALL_REPORTS_MODAL_ID),
    },
    dispatch
  ),
});

type OwnProps = {
  reportId?: UserReport['id'];
  onChange: (selectedOption: string | null) => void;
};

export type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const fromUserReportToOption = (
  userReportPartial: UserReport | UserReportPartial,
  isHidden: boolean
): Option => ({
  report: userReportPartial,
  value: userReportPartial.id,
  label: userReportPartial.name,
  isHidden,
});

const handleLoadOptions = debounce(
  (inputValue: string, callback: (userReportPartials: Option[]) => void) => {
    const paginationParams = {
      perPage: 100,
      page: 1,
    };

    api
      .fetchDatalabUserReportPartials({
        searchQuery: inputValue,
        paginationParams,
      })
      .then(response => {
        if (response.ok) {
          const userReportPartials = response.data.data;
          callback(
            userReportPartials.map((report: UserReportPartial) =>
              fromUserReportToOption(report, false)
            )
          );
        } else {
          callback([]);
        }
      });
  },
  350
);

export class DatalabReportSelectField extends React.Component<Props> {
  // Because the defaultOptions object is being generated within the
  // selectDefaultReportSelectOptions selector, a shallow comparison
  // of the prevProp and the current prop says that they're unequal,
  // hence the imperative shouldComponentUpdate usage below.
  //
  // Rerendering a <Select /> component causes the scroll position to go back to
  // the top - this prevents that behavior from occurring.
  shouldComponentUpdate(prevProps, prevState) {
    return !isEqual(prevProps, this.props) || !isEqual(prevState, this.state);
  }

  render() {
    const { reportId, report, defaultOptions, areDefaultOptionsLoading, onChange, actions } =
      this.props;

    const options: (Option | Group)[] = [
      // When a value is provided to react-select, it will only appear
      // in the input if the value exists in the options array provided to the
      // component.  Because options only contains a subset of latest and
      // favorited reports, it's possible and likely that the selected report
      // will not exist in options - in this scenario, the react-select input field
      // will be blank.  In order to account for this, we're injecting the selected
      // report as an option in the line below and setting an "isHidden" property
      // on the option.  The "isHidden" property is referenced in react-select's
      // renderOption prop further below, and is used to prevent the injected option from
      // appearing in the select list.
      ...(report ? [fromUserReportToOption(report, true)] : []),
      ...defaultOptions.map(option => ({
        label: option.name,
        options: option.options.map(option => fromUserReportToOption(option, false)),
      })),
    ];

    return (
      <AsyncSelectField
        isSearchable
        name="reportId"
        label="Report"
        styledConfig={{
          option: (args, controlProps, styles) => ({
            ...styles,
            padding: 0,
          }),
        }}
        renderGroupLabel={data => (
          <SGroupLabel>
            {data.label} ({data.options.length})
          </SGroupLabel>
        )}
        placeholder={areDefaultOptionsLoading ? 'Loading...' : 'Select report...'}
        // @ts-ignore TODO options are not unified and therefore compatible with Option | Group
        defaultOptions={options}
        onChange={onChange}
        loadOptions={handleLoadOptions}
        noOptionsMessage={({ inputValue }: { inputValue: string }) => {
          if (areDefaultOptionsLoading) {
            return 'Loading...';
          } else if (inputValue) {
            return `No reports matching ${inputValue} found.`;
          }

          return 'No reports found.';
        }}
        renderOption={props => {
          const {
            label,
            value,
            report: { lastModified, isFavorite, share },
            isHidden,
          } = props;

          if (isHidden) {
            return null;
          }

          const formattedDate = lastModified
            ? timeUtil.formatAsDisplayDate(lastModified.date)
            : null;
          const isSelectedOption = !!reportId && value === reportId;

          return (
            <SOption isSelected={isSelectedOption}>
              <SOptionReportInfo>
                <SOptionReportName isSelected={isSelectedOption}>{label}</SOptionReportName>
                <SOptionReportMeta isSelected={isSelectedOption}>
                  {lastModified && <span>{`Last modified by ${lastModified.name}`}</span>}
                  {formattedDate && <span> {formattedDate}</span>}
                </SOptionReportMeta>
              </SOptionReportInfo>
              <SOptionReportIcons>
                {isFavorite && (
                  <SOptionReportIcon>
                    <Icon name="star" variant="solid" />
                  </SOptionReportIcon>
                )}
                {share && share.isShared && (
                  <SOptionReportIcon>
                    <Icon name="share-alt" variant="solid" />
                  </SOptionReportIcon>
                )}
              </SOptionReportIcons>
            </SOption>
          );
        }}
        renderMenuList={children => (
          <React.Fragment>
            <div>{children}</div>
            {defaultOptions.length > 0 && (
              <SStickyMenuListButton
                data-test-id={seeAllReportsButtonSelector.testId}
                type="button"
                onClick={event => {
                  // A bit of trickery to remove focus from
                  // SelectField.  Otherwise, the SelectField
                  // dropdown closes but focus persists, which puts
                  // SelectField into a wonky state.  You won't be
                  // able to re-open the dropdown without first
                  // removing focus from SelectField, and then clicking
                  // SelectField again.
                  event.currentTarget.focus();
                  event.currentTarget.blur();
                  actions.onClickSeeAll();
                }}
              >
                See All
              </SStickyMenuListButton>
            )}
          </React.Fragment>
        )}
        renderSingleSelectedOption={selectedOption => <span>{selectedOption.label}</span>}
      />
    );
  }
}

export default connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(DatalabReportSelectField);
