import { UnpackPromise } from '@cmg/common';

import { makePostDownloadRequest } from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';
import { AtmOfferingRequestDto } from './types/AtmOffering';

/**
 * Download an xlsx of atm offerings
 */
export const downloadAtmOfferings = makePostDownloadRequest<AtmOfferingRequestDto>(
  dlgwApiClient,
  () => `/atms/download`
);

export type DownloadAtmOfferingsResponse = UnpackPromise<ReturnType<typeof downloadAtmOfferings>>;
