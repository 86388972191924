import { UnpackPromise } from '@cmg/common';

import { makePostDownloadRequest } from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';
import { CalendarOfferingsRequestDto } from './types/CalendarOfferingsRequestDto';

/**
 * Download an xlsx of calendar offerings
 */
export const downloadCalendarOfferings = makePostDownloadRequest<CalendarOfferingsRequestDto, {}>(
  dlgwApiClient,
  () => `/calendar/download`
);

export type DownloadCalendarOfferingsResponse = UnpackPromise<
  ReturnType<typeof downloadCalendarOfferings>
>;
