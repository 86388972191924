/**
 * @deprecated We need to create interceptors that do request and response transformation then apply those to identity SPA and remove then+catch.
 * Then we need to rewrite this interceptor to not do transformation, but to only handle side effects.
 * TODO make a ticket for this deprecation work.
 */
import {
  accessTokenExpirationRequestInterceptor,
  getAccessToken,
  getUserProfileLogContext,
} from '@cmg/auth';
import { apiTypes, loggerUtil, ToastManager } from '@cmg/common';
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import store from '../common/redux/store';
import routeFactory from '../common/util/routeFactory';
import { getAppSettings } from '../config/appSettings';
import { ErrorCode } from '../features/shared/auth/constants';
import { finishApiCall, startApiCall } from '../features/shared/spinner/ducks';
import { ErrorType, getInnerErrorCode } from './utils/error';
import { normalizeErrorResponse, normalizeSuccessResponse } from './utils/normalize-response';

export const ErrorMessage = {
  ENTITY_MODIFIED: 'Submitted data were modified by another user, please refresh your browser.',
  SERVER_ERROR: 'An unexpected error has occurred. Please try again or refresh your browser.',
  DOWNLOAD:
    'Your download failed. This is potentially due to the high volume of data requested. If the problem persists, please contact support.',
};

/**
 * getRequestInfoFromError returns a subset of request information
 * that is needed for logging network errors in datadog
 * @see {@link https://docs.datadoghq.com/logs/processing/attributes_naming_convention/#common-attributes}
 * @todo move to api-clients when ECM-3644 is merged
 */
function getRequestInfoFromError(error: AxiosError<any>) {
  return {
    method: error.config?.method,
    url: error.request.responseURL,
    status_code: error.response?.status,
    status_text: error.response?.statusText,
  };
}

/**
 * normalizeNetworkErrorLog Update the error message being passed into datadog
 * with the method and request url
 * @param error
 * @todo move to api-clients when ECM-3644 is merged
 */
function normalizeNetworkErrorLog(error: ErrorType) {
  return {
    ...error,
    message: `XHR error ${error.config?.method?.toUpperCase()} ${error.request.responseURL} ${
      error.message
    }`,
    stack: error.stack,
  };
}

export const responseErrorInterceptor = (error: ErrorType) => {
  const toastErrorConfig = { timeout: 10000 };

  if (!error.response) {
    // axios internal error
    loggerUtil.error(error, getUserProfileLogContext());
    return error;
  }

  if (error.response.status === 403 && getInnerErrorCode(error) === ErrorCode.IP_BLOCKED) {
    // explicitly redirect used to a concrete page from ID SPA
    loggerUtil.error(normalizeNetworkErrorLog(error), {
      ...getUserProfileLogContext(),
      http: getRequestInfoFromError(error),
    });
    window.location.href = routeFactory.error.getUrlPath({
      errorCode: apiTypes.ServiceErrorCode.IP_BLOCKED,
    });
  } else if (error.response.status === 404) {
    loggerUtil.error(normalizeNetworkErrorLog(error), {
      ...getUserProfileLogContext(),
      http: getRequestInfoFromError(error),
    });
  } else if (error.response.status === 409) {
    // multiple uses may have edited the same entity

    ToastManager.error(ErrorMessage.ENTITY_MODIFIED, toastErrorConfig);
  } else if (error.response.status === 422) {
    // no-op - should be handled by saga / components
  } else if (error.request.responseType === 'blob') {
    ToastManager.error(ErrorMessage.DOWNLOAD, toastErrorConfig);
  } else {
    // fallback, since all errors like err401 (not authenticated), err403 (not authorized) or even
    // err500 (and similar) are our defects in code or infrastructure
    loggerUtil.error(normalizeNetworkErrorLog(error), {
      ...getUserProfileLogContext(),
      http: getRequestInfoFromError(error),
    });
    ToastManager.error(ErrorMessage.SERVER_ERROR, toastErrorConfig);
  }

  return error;
};

export const normalizeRequestHeader = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const token = getAccessToken();

  if (token) {
    config.headers.set('Authorization', `Bearer ${token}`);
  }

  return config;
};

export const apiCallStartInterceptor = config => {
  store.dispatch(
    startApiCall({
      apiCallId: config.apiCallId,
      apiCallIdParams: config.apiCallIdParams,
    })
  );

  return config;
};

export const apiCallEndInterceptor = <TData = never>(
  response: AxiosResponse<TData> | AxiosError<TData>
) => {
  store.dispatch(
    finishApiCall({
      // @ts-ignore custom attributes in config are not defined in official config type
      apiCallId: response.config.apiCallId,
      // @ts-ignore custom attributes in config are not defined in official config type
      apiCallIdParams: response.config.apiCallIdParams,
    })
  );

  return response;
};

/**
 * Following allRequest and allResponse interceptor are required because of internal race-condition
 * inside axios (for error response, normalizeSuccessResponse might be called and it would be treated
 * as success response
 */

/**
 * @deprecated
 * @param config
 */
export const allRequestInterceptors = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const requestConfig = accessTokenExpirationRequestInterceptor(config);
  apiCallStartInterceptor(requestConfig);

  return normalizeRequestHeader(requestConfig);
};

/**
 * @deprecated
 * @param response
 */
export const allSuccessResponseInterceptors = (response: AxiosResponse) => {
  apiCallEndInterceptor(response);

  return normalizeSuccessResponse(response);
};

/**
 * @deprecated
 * @param error
 */
export const allErrorResponseInterceptors = (error: ErrorType) => {
  responseErrorInterceptor(error);
  apiCallEndInterceptor(error);

  return normalizeErrorResponse(error);
};

export const requestInterceptors = config => {
  const requestConfig = accessTokenExpirationRequestInterceptor(config);
  return normalizeRequestHeader(requestConfig);
};

export const successResponseInterceptors = (response: AxiosResponse) => {
  return normalizeSuccessResponse(response);
};

export const errorResponseInterceptors = (error: ErrorType) => {
  responseErrorInterceptor(error);

  return normalizeErrorResponse(error);
};

/**
 * Ruby Migration Feature Flag Interceptor
 */
const guidRegex = /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/;
const getEndpointCompatFeatureFlag = (config: InternalAxiosRequestConfig) => {
  let url = config.url ?? '';
  url = url.replace(guidRegex, ':guid');

  if (url.endsWith('/') && url.length > 1) {
    url = url.slice(0, -1);
  }

  const queryIndex = url.indexOf('?');
  if (queryIndex >= 0) {
    url = url.substring(0, queryIndex);
  }

  if (!url.startsWith('/')) {
    url = '/' + url;
  }

  const method = config.method?.toUpperCase() ?? 'GET';
  return `${method}${url}`;
};

export const rubyMigrationRequestInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const compatEndpoints = getAppSettings().features.rubyMigrationCompatEndpoints.split(';');
  if (compatEndpoints.indexOf(getEndpointCompatFeatureFlag(config)) >= 0) {
    config.baseURL = '/dlgw/api/v1/compat';
  } else {
    config.baseURL = '/dl/api/v1';
  }

  return config;
};
