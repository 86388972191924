import { type apiTypes, apiUtil } from '@cmg/common';
import { AxiosError } from 'axios';

export type ErrorType = AxiosError<{ error?: apiTypes.GenericServerError } | null | undefined>;

export function getInnerError(error: ErrorType) {
  const hasError = error.response && error.response.data && error.response.data.error;
  // if response is empty, fallback to generic server error with fallback text
  return apiUtil.normalizeError(hasError || 'Error Fallback');
}

export function getInnerErrorCode(error: ErrorType) {
  const innerError = getInnerError(error);
  return innerError ? innerError.code : null;
}
