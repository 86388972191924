import { TraitCode } from '@capital-markets-gateway/api-client-identity';
import {
  checkAccountTraits,
  checkPermissions,
  getAccountTraits,
  getUserPermissions,
  permissionsByEntity,
} from '@cmg/auth';

import { OfferingHeader } from '../../../api/dlgw/offerings/types/OfferingHeader';
import {
  columnCategoryLabel,
  columnGroupLabel,
  DatalabScreens,
  DatalabTableColumnCategory,
  DatalabTableColumnGroup,
} from '../constants';
import {
  CategorizedDatalabTableColumn,
  GroupedDatalabTableColumn,
} from './advanced-filters-config';
import { DatalabTableColumn, showInternationalDataOnScreen } from './datalabTableColumns';

/**
 * Set category of all given columns.
 *
 * @param columns - columns to add to given category
 * @param category - category to be used
 */
export const setColumnsCategory = (
  columns: DatalabTableColumn[],
  category: DatalabTableColumnCategory
): CategorizedDatalabTableColumn[] => {
  return columns.map(col => ({ ...col, category: columnCategoryLabel[category] }));
};

/**
 * Combined columns from different categories into groups
 * This is used to create a checkbox to turn on/off
 * all local currency or USD fields
 * but can also be utilized as a general group
 *
 * @param columns - columns to add to given group
 * @param group - group to be used
 */
export const setColumnsGroup = (
  columns: DatalabTableColumn[],
  group: DatalabTableColumnGroup
): GroupedDatalabTableColumn[] => {
  return columns.map(col => ({ ...col, group: columnGroupLabel[group] }));
};

export const isInternationalOfferingsOn = (): boolean => {
  const canViewInternationalData = checkPermissions(getUserPermissions(), [
    permissionsByEntity.InternationalOffering.READ,
  ]);
  return canViewInternationalData;
};

export const getInternationalPermission = (screen: DatalabScreens): boolean =>
  isInternationalOfferingsOn() && showInternationalDataOnScreen(screen);

export const isInternationalOffering = (offering: OfferingHeader | null): boolean =>
  !!offering && !!offering.pricingCurrencyCode && offering.pricingCurrencyCode !== 'USD';

export const isInternationalOfferingTypesOn = (): boolean => {
  return checkAccountTraits(getAccountTraits(), [TraitCode.INTERNATIONAL_OFFERING_TYPES]);
};

export const canViewConvertData = (): boolean => {
  return checkPermissions(getUserPermissions(), [permissionsByEntity.ConvertsOffering.READ]);
};

export const isConvertsPhaseTwoOn = (): boolean => {
  return canViewConvertData();
};

export const isAtmEnabled = (): boolean => {
  const canViewAtmData = checkPermissions(getUserPermissions(), [permissionsByEntity.ATM.READ]);
  return canViewAtmData;
};

export const isDownloadExcelOn = (): boolean => {
  return checkAccountTraits(getAccountTraits(), [TraitCode.DL_EXCEL_DOWNLOAD]);
};
