import { UnpackPromise } from '@cmg/common';

import { makePostDownloadRequest } from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';
import { GlobalLeagueTableRequestDto } from './types/GlobalLeagueTable';

/**
 * Download an xlsx of Global League Table rows
 */
export const downloadGlobalLeagueTable = makePostDownloadRequest<GlobalLeagueTableRequestDto>(
  dlgwApiClient,
  () => `/globalLeagueTable/download`
);

export type DownloadGlobalLeagueTableResponse = UnpackPromise<
  ReturnType<typeof downloadGlobalLeagueTable>
>;
