import { makeFetchListRequest } from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';
import { UsersList } from './types/UserList';

export type UsersQuery = {
  requiredPermissions: string[];
};

export const fetchOrganizationUsers = makeFetchListRequest<UsersList, UsersQuery>(
  dlgwApiClient,
  '/users'
);

export type FetchOrganizationUsersResponse = ReturnType<typeof fetchOrganizationUsers>;
