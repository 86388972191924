import {
  makeParameterlessCreateEntityRequest,
  makeParameterlessFetchEntityRequest,
} from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';
import { Fund } from './types/Fund';

export const createFund = makeParameterlessCreateEntityRequest<{ name: string }, Fund>(
  dlgwApiClient,
  '/funds'
);

export const fetchFunds = makeParameterlessFetchEntityRequest<Fund[]>(dlgwApiClient, '/funds');
