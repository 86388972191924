import { UnpackPromise } from '@cmg/common';

import { makePostDownloadRequest } from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';
import { IssuersDownloadRequestDto } from './types/Issuer';

/**
 * Download an xlsx of convertible offerings
 */
export const downloadIssuers = makePostDownloadRequest<IssuersDownloadRequestDto>(
  dlgwApiClient,
  () => `/issuers/download`
);

export type DownloadIssuersResponse = UnpackPromise<ReturnType<typeof downloadIssuers>>;
