import { UnpackPromise } from '@cmg/common';

import { makeFetchEntityRequest } from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';
import { OfferingHeader } from './types/OfferingHeader';

export const fetchOfferingHeader = makeFetchEntityRequest<OfferingHeader>(
  dlgwApiClient,
  '/offerings/:id/header'
);

export type FetchOfferingHeaderResponse = UnpackPromise<ReturnType<typeof fetchOfferingHeader>>;
