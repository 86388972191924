import { checkPermissions, permissionsByEntity, PublicAuthStateProps, withAuth } from '@cmg/auth';
import { urlUtil } from '@cmg/common';
import flattenDeep from 'lodash/flattenDeep';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { DATALAB_API_CALL_ID } from '../../../api/dl-ruby/datalab-api';
import { searchSponsorOptions } from '../../../api/dlgw/filter-options';
import routeFactory from '../../../common/util/routeFactory';
import { SponsorOption } from '../../../types/domain/firm/sponsorOptions';
import { fetchOrganizationUsersRequest } from '../../shared/ducks';
import { selectIsInProgress } from '../../shared/spinner/ducks';
import DatalabScreen from '../components/DatalabScreen';
import {
  downloadDatalabTable,
  fetchAdvisoryOptionsActions,
  fetchFundOptionsActions,
  fetchUnderwriterOptionsActions,
  fetchUserReportPartialsActions,
  initDatalabAction,
  loadReportActions,
  reInitDatalab,
  reloadDatalabAction,
  selectActiveFilterCount,
  selectAdvisoryOptions,
  selectFilterDefaultValues,
  selectFilters,
  selectFundOptions,
  selectSponsorOptions,
  selectTableConfig,
  selectUnderwriterOptions,
} from '../ducks';
import { getFilterSettings, spacOptions } from '../model/filters.model';
import { DatalabFilterValues } from '../types';

const mapStateToProps = (state, props) => {
  const screen = props.match.params.datalabScreen;
  const screenType = props.match.params.datalabScreenType;
  const query = urlUtil.queryParse<{ [key: string]: string }>(props.location.search);

  return {
    advisoryOptions: selectAdvisoryOptions(state),
    fundOptions: selectFundOptions(state),
    sponsorOptions: selectSponsorOptions(state),
    underwriterOptions: selectUnderwriterOptions(state),
    screen,
    screenType,
    filterDefaultValues: selectFilterDefaultValues(state),
    filterValues: selectFilters(state),
    isReport: !!query.report,
    query,
    isLoading: selectIsInProgress(state, DATALAB_API_CALL_ID),
    activeFilterCount: selectActiveFilterCount(state, screen),
    tableConfig: selectTableConfig(state, screen),
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      initDatalabAction: initDatalabAction,
      reInitDatalabAction: reInitDatalab,
      reloadDatalabAction: reloadDatalabAction,
      fetchDatalabUserReportPartials: fetchUserReportPartialsActions.request,
      fetchUnderwriterOptions: fetchUnderwriterOptionsActions.request,
      fetchAdvisoryOptions: fetchAdvisoryOptionsActions.request,
      fetchFundOptions: fetchFundOptionsActions.request,
      loadDatalabReport: loadReportActions.request,
      downloadDatalabTable: downloadDatalabTable,
      fetchOrganizationUsers: fetchOrganizationUsersRequest,
    },
    dispatch
  ),
});

type RouteProps = RouteComponentProps<{ datalabScreen: string; datalabScreenType: string }>;
// TODO: we should not use any types

export type DownloadPayload = {
  includeOfferingNotes?: boolean;
  includeIoiNotes?: boolean;
  includeUnderwriters?: boolean;
  includeSponsors?: boolean;
  includeFundIoi?: boolean;
  includeAdvisors?: boolean;
  screen?: string;
};

export type DownloadReportProps = { columns: any[] } & DownloadPayload;
export type Props = RouteProps &
  PublicAuthStateProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export class DatalabContainerComponent extends React.PureComponent<Props> {
  componentDidMount() {
    const { actions, query, userPermissions, screen, screenType } = this.props;

    actions.fetchDatalabUserReportPartials({ paginationParams: { perPage: 12 } });
    actions.fetchUnderwriterOptions();
    actions.fetchAdvisoryOptions();
    actions.fetchOrganizationUsers();

    if (checkPermissions(userPermissions, [permissionsByEntity.FundIoi.READ])) {
      actions.fetchFundOptions();
    }

    if (query.report) {
      actions.loadDatalabReport({ reportId: query.report });
    } else if (screen && screenType) {
      actions.initDatalabAction({ section: screen, sectionType: screenType, query });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { actions, isReport, query, screen, screenType } = this.props;

    // loads report if query.report id has changed
    if (query.report && (query.report !== prevProps.query.report || prevProps.screen !== screen)) {
      return actions.loadDatalabReport({ reportId: query.report });
    }

    if (
      !isReport &&
      screen &&
      screenType &&
      (prevProps.screen !== screen || prevProps.screenType !== screenType)
    ) {
      if (!prevProps.screenType && screenType) {
        // redirection from datalab/screen to datalab/screen/screenType cause ini datalab not being called in onMount
        // this condition ensures that init action is triggered (re-init action does not make sense without init)
        actions.initDatalabAction({ section: screen, sectionType: screenType, query });
      } else {
        actions.reInitDatalabAction({
          prevScreen: prevProps.screen,
          prevScreenType: prevProps.screenType,
          nextScreen: this.props.screen,
          nextScreenType: this.props.screenType,
          nextUrlQuery: this.props.query,
        });
      }
    }
  }

  handleOnTogglerChanged = sectionType => {
    const { history, screen } = this.props;
    const url = routeFactory.datalab.getUrlPath({ screen, type: sectionType.toLowerCase() });
    history.push(url);
  };

  handleOnFilterChange = filters => {
    const { actions, screen, screenType } = this.props;
    // report screen doesn't need filter change - triggers unecessary reloading of datalab and 500 error
    if (screen !== 'report') {
      actions.reloadDatalabAction({ section: screen, sectionType: screenType, filters, page: 1 });
    }
  };

  handleOnQuickFilterChange = (values: Partial<DatalabFilterValues>) => {
    const { actions, filterValues, screen, screenType } = this.props;
    actions.reloadDatalabAction({
      section: screen,
      sectionType: screenType,
      filters: { ...filterValues, ...values },
    });
  };

  handleOnPaginationOrOrderByChange = ({ pagination, order }) => {
    const { actions, screen, screenType } = this.props;
    actions.reloadDatalabAction({
      section: screen,
      sectionType: screenType,
      page: pagination ? pagination.activePage : undefined,
      perPage: pagination ? pagination.itemsPerPage : undefined,
      orderBy: order ? order.orderBy : undefined,
      orderByDirection: order ? order.orderByType : undefined,
    });
  };

  handleOnDownload = ({
    columns,
    includeOfferingNotes = false,
    includeIoiNotes = false,
    includeUnderwriters = false,
    includeSponsors = false,
    includeFundIoi = false,
    includeAdvisors = false,
  }: DownloadReportProps) => {
    const { actions, screen } = this.props;

    // Flatten exportFields array when we have a column with multiple BE fields
    const exportColumns = flattenDeep(columns.map(c => c.exportFields || c.field));
    actions.downloadDatalabTable({
      section: screen,
      columns: exportColumns,
      includeAdvisors,
      includeOfferingNotes,
      includeIoiNotes,
      includeUnderwriters,
      includeSponsors,
      includeFundIoi,
    });
  };

  handleSponsorSearch = async (input: string): Promise<SponsorOption[]> => {
    const response = await searchSponsorOptions({ searchText: input, page: 1, perPage: 100 });
    if (response.ok) {
      return response.data.data;
    }

    return [];
  };

  render() {
    const {
      advisoryOptions,
      fundOptions,
      sponsorOptions,
      underwriterOptions,
      screen,
      screenType,
      filterDefaultValues,
      isLoading,
      activeFilterCount,
      tableConfig,
    } = this.props;

    const filterSettings = getFilterSettings(screen);

    return (
      <DatalabScreen
        tableConfig={tableConfig}
        activeFilterCount={activeFilterCount}
        filterSettings={filterSettings}
        filterDefaultValues={filterDefaultValues}
        screen={screen}
        screenType={screenType}
        isLoading={isLoading}
        advisoryOptions={advisoryOptions}
        fundsOptions={fundOptions}
        sponsorOptions={sponsorOptions}
        handleSponsorSearch={this.handleSponsorSearch}
        underwriterOptions={underwriterOptions}
        spacOptions={spacOptions}
        onQuickFilterChange={this.handleOnQuickFilterChange}
        onTogglerChanged={this.handleOnTogglerChanged}
        onFilterChange={this.handleOnFilterChange}
        onPaginationOrOrderByChange={this.handleOnPaginationOrOrderByChange}
        onDownload={this.handleOnDownload}
      />
    );
  }
}

const DatalabContainerWithAuth = withAuth(DatalabContainerComponent);

export default connect(mapStateToProps, mapDispatchToProps)(DatalabContainerWithAuth);
