import { duckPartFactory, reduxUtil, ToastManager } from '@cmg/common';
import { AnyAction, combineReducers } from 'redux';
import { call, delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import apiCalls, {
  GetNotificationsParams,
  GetNotificationsResponse,
  GetNotificationSummaryResponse,
  MarkAllAsReadResponse,
  MarkAsReadParams,
  PutMarkAsReadResponse,
} from '../../../api/id/notifications/apiCalls';
import { RootState } from '../../../common/redux/rootReducer';
import { getAppSettings } from '../../../config/appSettings';
import { Pagination } from '../../../types/api/pagination';
import { NotificationActionType } from '../../../types/domain/notifications/constants';
import {
  Notification,
  NotificationSummary,
} from '../../../types/domain/notifications/notification';
import { toastMessages } from '../constants/messages';

// TODO: remove makeGenericServerError when real API is introduced

/**
 * ACTION TYPES
 */

export const fetchNotificationsDuckParts = duckPartFactory.makeAPIDuckParts<
  GetNotificationsParams,
  {
    data: Notification[];
    pagination: Pagination;
  }
>({
  prefix: 'notifications/FETCH_NOTIFICATIONS',
});

export const fetchNotificationSummaryDuckParts = duckPartFactory.makeAPIDuckParts<
  undefined,
  NotificationSummary
>({
  prefix: 'notifications/FETCH_NOTIFICATIONS_SUMMARY',
});

export const markAsReadDuckParts = duckPartFactory.makeAPIDuckParts<
  MarkAsReadParams,
  undefined,
  MarkAsReadParams
>({
  prefix: 'notifications/MARK_NOTIFICATION_READ',
});

export const markAllAsReadDuckParts = duckPartFactory.makeAPIDuckParts<any>({
  prefix: 'notifications/MARK_ALL_NOTIFICATIONS_READ',
});

export enum ActionTypes {
  POLL_NOTIFICATIONS_START = 'notifications/POLL_NOTIFICATIONS_START',
  POLL_NOTIFICATIONS_STOP = 'notifications/POLL_NOTIFICATIONS_STOP',
  POLL_NOTIFICATIONS_TICK = 'notifications/POLL_NOTIFICATIONS_TICK',
  SHOW_NOTIFICATIONS = 'notifications/SHOW_NOTIFICATIONS',
  HIDE_NOTIFICATIONS = 'notifications/HIDE_NOTIFICATIONS',
  FILTERS_SELECTED = 'notifications/FILTERS_SELECTED',
  FILTER_UNREAD_ONLY = 'notifications/FILTER_UNREAD_ONLY',
  RESET_NOTIFICATIONS_DATA = 'notifications/RESET_NOTIFICATIONS_DATA',
}

/**
 * ACTION CREATORS
 */

/**
 * fetches notification data
 */
export const fetchNotifications = fetchNotificationsDuckParts.actionCreators.request;
type FetchNotificationsAction = ReturnType<typeof fetchNotifications>;

/**
 * fetches summary of notification counts by unread and actionType
 */
export const fetchNotificationSummary = () =>
  fetchNotificationSummaryDuckParts.actionCreators.request(undefined);
type FetchNotificationSummaryAction = ReturnType<typeof fetchNotificationSummary>;
type FetchNotificationSummarySuccessAction = ReturnType<
  typeof fetchNotificationSummaryDuckParts.actionCreators.success
>;

/**
 * updates status of a single notification
 */
export const markAsRead = markAsReadDuckParts.actionCreators.request;
type MarkAsReadAction = ReturnType<typeof markAsRead>;

export const markAsReadFailure = markAsReadDuckParts.actionCreators.failure;

/*
 * update all notifications by actionType or status
 */

export const markAllAsRead = markAllAsReadDuckParts.actionCreators.request;
type MarkAllAsReadAction = ReturnType<typeof markAllAsRead>;

/**
 * toggle visibility of slideout
 */
export const showNotifications = () => ({
  type: ActionTypes.SHOW_NOTIFICATIONS,
});

export const hideNotifications = () => ({
  type: ActionTypes.HIDE_NOTIFICATIONS,
});

/**
 * toggle filtering by unread only
 */
export const filterUnreadOnly = (payload: boolean) => ({
  type: ActionTypes.FILTER_UNREAD_ONLY,
  payload,
});

/**
 * filtering by actionType[]
 */
export const selectFilters = (payload: NotificationActionType[]) => ({
  type: ActionTypes.FILTERS_SELECTED,
  payload,
});

/**
 * starts or stops polling of notification summary count
 */
export const startNotificationsPolling = () => ({
  type: ActionTypes.POLL_NOTIFICATIONS_START,
});

export const stopNotificationsPolling = () => ({
  type: ActionTypes.POLL_NOTIFICATIONS_STOP,
});

export const pollNotifications = () => ({
  type: ActionTypes.POLL_NOTIFICATIONS_TICK,
});

/*
 * resets notifications data within duck parts for filter updating and fetching
 */
export const resetNotificationsData = () => ({
  type: ActionTypes.RESET_NOTIFICATIONS_DATA,
});

type Actions = {
  [ActionTypes.POLL_NOTIFICATIONS_START]: ReturnType<typeof startNotificationsPolling>;
  [ActionTypes.POLL_NOTIFICATIONS_STOP]: ReturnType<typeof stopNotificationsPolling>;
  [ActionTypes.POLL_NOTIFICATIONS_TICK]: ReturnType<typeof pollNotifications>;
  [ActionTypes.SHOW_NOTIFICATIONS]: ReturnType<typeof showNotifications>;
  [ActionTypes.HIDE_NOTIFICATIONS]: ReturnType<typeof hideNotifications>;
  [ActionTypes.FILTER_UNREAD_ONLY]: ReturnType<typeof filterUnreadOnly>;
  [ActionTypes.FILTERS_SELECTED]: ReturnType<typeof selectFilters>;
  [ActionTypes.RESET_NOTIFICATIONS_DATA]: ReturnType<typeof resetNotificationsData>;
};

/**
 * REDUCERS
 */

const { createReducer } = reduxUtil;

export type ReducerState = {
  showSlideout: boolean;
  polling: boolean;
  unreadOnly: boolean;
  filters: NotificationActionType[];
  notifications: typeof fetchNotificationsDuckParts.initialState;
  summary: typeof fetchNotificationSummaryDuckParts.initialState;
  markAsRead: typeof markAsReadDuckParts.initialState;
  markAllAsRead: typeof markAllAsReadDuckParts.initialState;
};

export const initialState = {
  showSlideout: false,
  polling: false,
  unreadOnly: false,
  filters: [],
  notifications: fetchNotificationsDuckParts.initialState,
  summary: fetchNotificationSummaryDuckParts.initialState,
  markAsRead: markAsReadDuckParts.initialState,
  markAllAsRead: markAllAsReadDuckParts.initialState,
};

const showSlideoutReducer = createReducer<ReducerState['showSlideout'], Actions>(
  initialState.showSlideout,
  {
    [ActionTypes.SHOW_NOTIFICATIONS]: () => true,
    [ActionTypes.HIDE_NOTIFICATIONS]: () => false,
  }
);

const pollingReducer = createReducer<ReducerState['polling'], Actions>(initialState.polling, {
  [ActionTypes.POLL_NOTIFICATIONS_START]: () => true,
  [ActionTypes.POLL_NOTIFICATIONS_STOP]: () => false,
});

const unreadOnlyReducer = createReducer<ReducerState['unreadOnly'], Actions>(
  initialState.unreadOnly,
  {
    [ActionTypes.FILTER_UNREAD_ONLY]: (curState, { payload }) => payload,
  }
);

const filtersReducer = createReducer<ReducerState['filters'], Actions>(initialState.filters, {
  [ActionTypes.FILTERS_SELECTED]: (curState, { payload }) => payload,
});

/*
 * updates summary totals by actionType based on marking notification as READ or UNREAD
 */
const updateSummaryTotalsObject = (
  curState: ReducerState['summary'],
  action: { payload: MarkAsReadParams }
) => {
  const { data } = curState;
  if (!data) {
    return curState;
  }

  const { totalUnread = 0, actionTypeSummaries = [] } = data;
  const { notification, isRead } = action.payload;

  // if marking isRead=true, decrement, if marking isRead=false, increment
  const delta: number = isRead ? -1 : 1;

  // update the totalUnread depending on UNREAD vs READ delta above
  const updatedTotalUnread: number = totalUnread + delta;

  // go through each of the actionTypes within summary and inc/dec based off notification type updated
  const updatedActionTypes = actionTypeSummaries.map(actionTypeSummary => {
    const { unread, actionType } = actionTypeSummary;

    if (actionType === notification.actionType) {
      const updatedActionTypeUnread = unread + delta;
      return {
        ...actionTypeSummary,
        unread: updatedActionTypeUnread,
      };
    } else {
      return actionTypeSummary;
    }
  });

  const updated = {
    ...curState,
    data: {
      ...data,
      totalUnread: updatedTotalUnread,
      actionTypeSummaries: updatedActionTypes,
    },
  };
  return updated;
};

/*
 * update unread summary counts when marking all as read
 */
const markAllSummaryTotalsAsRead = (curState: ReducerState['summary']) => {
  const { data } = curState;
  if (!data) {
    return { ...curState };
  }

  const { actionTypeSummaries = [] } = data;
  // update the totalUnread to 0 and corresponding actionTypes unread to 0 as well
  const updated = {
    ...curState,
    data: {
      ...data,
      totalUnread: 0,
      actionTypeSummaries: actionTypeSummaries.map(actionType => ({ ...actionType, unread: 0 })),
    },
  };
  return updated;
};

/*
 * custom reducer which handles summary success as well as incrementing/decrementing totals from updating notification status
 */
const customSummaryReducer = createReducer<
  ReducerState['summary'],
  FetchNotificationSummarySuccessAction
>(initialState.summary, {
  [markAsReadDuckParts.actionTypes.REQUEST]: updateSummaryTotalsObject,
  [markAsReadDuckParts.actionTypes.FAILURE]: updateSummaryTotalsObject,
  [markAllAsReadDuckParts.actionTypes.SUCCESS]: markAllSummaryTotalsAsRead,
});

/*
 * combined reducers from duck parts + custom summary reducer
 */
const summaryReducers = (
  summaryState: ReducerState['summary'] | undefined,
  action
): ReducerState['summary'] => {
  const summaryReducerState = fetchNotificationSummaryDuckParts.reducer(summaryState, action);
  return customSummaryReducer(summaryReducerState, action);
};

/*
 * updates a single notification's status
 */
const updateNotificationObject = (curState, action: { payload: MarkAsReadParams }) => {
  const { notification, isRead } = action.payload;
  return {
    ...curState,
    data: {
      ...curState.data,
      data: curState.data.data.map(n => (n.id === notification.id ? { ...n, isRead } : n)),
    },
  };
};

/*
 * updates all notifications (or by actionType) to status=READ
 */
const markAllNotificationsAsRead = curState => {
  const { data } = curState;
  return {
    ...curState,
    data: {
      ...data,
      data: data.data.map(n => ({ ...n, isRead: true })),
    },
  };
};

/*
 * custom reducer for notifications
 */
const customNotificationsReducer = createReducer<ReducerState['notifications'], any>(
  initialState.notifications,
  {
    [ActionTypes.RESET_NOTIFICATIONS_DATA]: () => fetchNotificationsDuckParts.initialState,
    [markAsReadDuckParts.actionTypes.REQUEST]: updateNotificationObject,
    [markAsReadDuckParts.actionTypes.FAILURE]: updateNotificationObject,
    [markAllAsReadDuckParts.actionTypes.SUCCESS]: markAllNotificationsAsRead,
  }
);

/*
 * combined reducers from duck parts + custom notifications reducer
 */
const notificationsReducers = (
  notificationsState: ReducerState['notifications'] | undefined,
  action
): ReducerState['notifications'] => {
  const notificationsReducerState = fetchNotificationsDuckParts.reducer(notificationsState, action);
  return customNotificationsReducer(notificationsReducerState, action);
};

/*
 * This reducer acts on the entire state of this duck. Has access to duck state and must return duck state.
 * Keep summary state from resetting when slideout closes
 */
const crossSliceReducer = createReducer<ReducerState, Actions>(initialState, {
  [ActionTypes.HIDE_NOTIFICATIONS]: curState => ({
    ...initialState,
    summary: curState.summary,
    filters: curState.filters,
  }),
});

const combinedReducers = combineReducers<ReducerState>({
  showSlideout: showSlideoutReducer,
  polling: pollingReducer,
  unreadOnly: unreadOnlyReducer,
  filters: filtersReducer,
  notifications: notificationsReducers,
  summary: summaryReducers,
  markAsRead: markAsReadDuckParts.reducer,
  markAllAsRead: markAllAsReadDuckParts.reducer,
});

// Combines our individual slice reducers and the cross slice reducer.
export default function duckReducer(state: ReducerState = initialState, action: AnyAction) {
  const intermediateState = combinedReducers(state, action);
  const finalState = crossSliceReducer(intermediateState, action);
  return finalState;
}

/**
 * SELECTORS
 */
const selectState = (state: RootState): ReducerState => state.notifications;
export const selectShowSlideout = (state: RootState) => selectState(state).showSlideout;
export const selectFilterUnreadOnly = (state: RootState) => selectState(state).unreadOnly;
export const selectNotificationFilters = (state: RootState) => selectState(state).filters;

const notificationSelectors = fetchNotificationsDuckParts.makeSelectors(
  state => selectState(state).notifications
);

export const selectNotificationsLoading = notificationSelectors.selectLoading;
export const selectNotificationsError = notificationSelectors.selectError;
export const selectNotifications = createSelector(
  [notificationSelectors.selectData, selectFilterUnreadOnly],
  (successBody, unreadOnly) => {
    if (!successBody) {
      return [];
    }

    const data = successBody.data;
    // when unread only filter is active server will only return unread data
    // the filter below ensures its removed from the list if marked as read
    return unreadOnly ? data.filter(({ isRead }) => isRead === false) : data;
  }
);

export const selectNotificationsPagination = (state: RootState) => {
  const successBody = notificationSelectors.selectData(state);
  return successBody ? successBody.pagination : null;
};

const notificationSummarySelectors = fetchNotificationSummaryDuckParts.makeSelectors(
  state => selectState(state).summary
);

export const selectTotalUnreadCount = createSelector(
  [notificationSummarySelectors.selectData],
  summary => {
    return summary ? summary.totalUnread : 0;
  }
);

export const selectAvailableNotificationFilters = createSelector(
  [notificationSummarySelectors.selectData],
  summary => {
    return summary?.actionTypeSummaries
      ? summary.actionTypeSummaries.map(({ actionType }) => actionType)
      : [];
  }
);

const markAllAsReadSelectors = markAllAsReadDuckParts.makeSelectors(
  state => selectState(state).markAllAsRead
);

export const selectMarkAllAsReadLoading = markAllAsReadSelectors.selectLoading;

/**
 * SAGAS
 */
export function* fetchNotificationsSaga({ payload }: FetchNotificationsAction) {
  // fetch the summary when we fetch notifications - keep everything in sync
  yield put(fetchNotificationSummary());

  const selectedFilters: NotificationActionType[] = yield select(selectNotificationFilters);
  const availableFilters: NotificationActionType[] = yield select(
    selectAvailableNotificationFilters
  );
  const unreadOnly: boolean = yield select(selectFilterUnreadOnly);

  // if available = selected OR if selected = 0
  const allFiltersSelected =
    availableFilters.length > 0 &&
    (availableFilters.length === selectedFilters.length || selectedFilters.length === 0);
  const unreadFilter = unreadOnly ? { isRead: false } : {};
  const actionTypeFilters = allFiltersSelected ? {} : { actionTypes: selectedFilters };

  const response: GetNotificationsResponse = yield call(apiCalls.fetchNotifications, {
    ...payload,
    ...unreadFilter,
    ...actionTypeFilters,
    includeTotals: true,
  });

  if (response.ok) {
    const { data, pagination } = response.data;
    const notifications = yield select(selectNotifications);

    // concat new data to existing notifications[], update pagination
    yield put(
      fetchNotificationsDuckParts.actionCreators.success({
        data: notifications.concat(data),
        pagination,
      })
    );
  } else {
    yield put(fetchNotificationsDuckParts.actionCreators.failure(response.data.error));
  }
}

/*
 * fetches the summary (aggregated totals object) from server to update counts client side
 */
export function* fetchNotificationSummarySaga() {
  const response: GetNotificationSummaryResponse = yield call(apiCalls.fetchNotificationSummary);

  if (response.ok) {
    yield put(fetchNotificationSummaryDuckParts.actionCreators.success(response.data));
  } else {
    yield put(fetchNotificationSummaryDuckParts.actionCreators.failure(response.data.error));
  }
}

/*
 * updates a single notification status
 */
export function* markAsReadSaga({ payload }: MarkAsReadAction) {
  const { notification, isRead } = payload;
  const response: PutMarkAsReadResponse = yield call(apiCalls.markAsRead, payload);

  if (response.ok) {
    // good job, reducer already took care of it
    yield put(markAsReadDuckParts.actionCreators.success(undefined));
  } else {
    // on fail, go and revert the notification we just optimistically updated before the request was made
    yield put(
      markAsReadDuckParts.actionCreators.failure({
        notification,
        isRead: isRead === true ? false : true,
      })
    );
  }
}

/*
 * calls an endpoint to batch mark all as read on server
 */
export function* markAllAsReadSaga() {
  const response: MarkAllAsReadResponse = yield call(apiCalls.markAllAsRead);

  if (response.ok) {
    yield put(markAllAsReadDuckParts.actionCreators.success(undefined));
  } else {
    ToastManager.error(toastMessages.error.notifications.markAllAsReadFailed);
    yield put(markAllAsReadDuckParts.actionCreators.failure(response.data.error));
  }
}

/*
 * resets all data excluding filter info, then fetch
 */
export function* refreshDataSaga() {
  yield put(resetNotificationsData());
  yield put(fetchNotificationsDuckParts.actionCreators.request({}));
}

/*
 * when setting filters this delays/debounces refreshing data
 */
export function* selectFiltersSaga() {
  // delay for 1s when selecting a filter - prevents fetching if the user is quickly selecting/deselecting
  yield delay(1000);
  yield call(refreshDataSaga);
}

/*
 * the polling timer saga itself
 */
export function* pollNotificationsSaga() {
  const appSettings = getAppSettings();
  // timeout in ms from env var
  const timeout: number = appSettings.notifications.pollingIntervalInMin * 60 * 1000;

  while (true) {
    // make a summary request and wait for timeout until requesting again
    yield put(fetchNotificationSummary());
    yield delay(timeout);
  }
}

/*
 * starts the polling saga, but will cancel it if stop action is dispatched
 */
export function* watchNotificationPollingSaga() {
  // on polling start, trigger the timer saga above, but in the event of a stop action, cancel everything and stop timer.
  yield race({
    task: call(pollNotificationsSaga),
    cancel: take(ActionTypes.POLL_NOTIFICATIONS_STOP),
  });
}

export function* notificationsSaga() {
  yield takeLatest<FetchNotificationsAction>(
    fetchNotificationsDuckParts.actionTypes.REQUEST,
    fetchNotificationsSaga
  );

  yield takeLatest<FetchNotificationSummaryAction>(
    fetchNotificationSummaryDuckParts.actionTypes.REQUEST,
    fetchNotificationSummarySaga
  );

  /*
   * using takeEvery instead of takeLatest allows every mark as read request to be sent, even if fast-clicking.
   * when clicking “Mark as Read” on Notification A then immediately on Notification B, the updateStatus saga will be cancelled for A.
   */
  yield takeEvery<MarkAsReadAction>(markAsReadDuckParts.actionTypes.REQUEST, markAsReadSaga);

  yield takeLatest<MarkAllAsReadAction>(
    markAllAsReadDuckParts.actionTypes.REQUEST,
    markAllAsReadSaga
  );

  yield takeLatest<Actions[ActionTypes.POLL_NOTIFICATIONS_START]>(
    ActionTypes.POLL_NOTIFICATIONS_START,
    watchNotificationPollingSaga
  );

  yield takeLatest<Actions[ActionTypes.FILTERS_SELECTED]>(
    ActionTypes.FILTERS_SELECTED,
    selectFiltersSaga
  );

  yield takeLatest<Actions[ActionTypes.FILTER_UNREAD_ONLY]>(
    ActionTypes.FILTER_UNREAD_ONLY,
    refreshDataSaga
  );
}
