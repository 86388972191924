import { UnpackPromise } from '@cmg/common';

import { makePostDownloadRequest } from '../../utils/request-factory';
import { dlgwApiClient } from '../apiClient';
import { ConvertibleOfferingRequestDto } from './types/ConvertibleOffering';

/**
 * Download an xlsx of convertible offerings
 */
export const downloadConvertibleOfferings = makePostDownloadRequest<ConvertibleOfferingRequestDto>(
  dlgwApiClient,
  () => `/converts/download`
);

export type DownloadConvertibleOfferingsResponse = UnpackPromise<
  ReturnType<typeof downloadConvertibleOfferings>
>;
