import { checkPermissions, getUserPermissions, permissionsByEntity } from '@cmg/auth';
import { reduxUtil } from '@cmg/common';
import merge from 'lodash/merge';
import { AnyAction, combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';

import * as dlgwApi from '../../api/dlgw';
import { fetchOrganizationUsers, FetchOrganizationUsersResponse } from '../../api/dlgw';
import { getViewportHeight, getViewportWidth } from '../../common/helpers/device-helpers';
import { objectSnakeToCamel } from '../../common/helpers/url-helpers';
import { RootState } from '../../common/redux/rootReducer';
import { ApiResponse } from '../../types/api/ApiResponse';
import { User } from '../../types/domain/user/user';
import { UserSettings } from '../../types/domain/user/userSettings';

/**
 * ACTION TYPES
 */
export enum ActionTypes {
  SET_DEVICE_VIEWPORT_DIMENSIONS = 'shared/SET_DEVICE_VIEWPORT_DIMENSIONS',
  FETCH_USER_SETTINGS_REQUEST = 'shared/FETCH_USER_SETTINGS_REQUEST',
  FETCH_USER_SETTINGS_SUCCESS = 'shared/FETCH_USER_SETTINGS_SUCCESS',
  SET_USER_SETTINGS_REQUEST = 'shared/SET_USER_SETTINGS_REQUEST',
  SET_USER_SETTINGS_SUCCESS = 'shared/SET_USER_SETTINGS_SUCCESS',
  FETCH_ORGANIZATION_USERS_REQUEST = 'shared/FETCH_ORGANIZATION_USERS_REQUEST',
  FETCH_ORGANIZATION_USERS_SUCCESS = 'shared/FETCH_ORGANIZATION_USERS_SUCCESS',
  FETCH_SHARED_REPORT_ORGANIZATION_USERS_REQUEST = 'shared/FETCH_SHARED_REPORT_ORGANIZATION_USERS_REQUEST',
  FETCH_SHARED_REPORT_ORGANIZATION_USERS_SUCCESS = 'shared/FETCH_SHARED_REPORT_ORGANIZATION_USERS_SUCCESS',
}

/**
 * ACTIONS
 */
export const setDeviceViewportDimensions = (params: { width: number; height: number }) => ({
  type: ActionTypes.SET_DEVICE_VIEWPORT_DIMENSIONS,
  payload: params,
});

/**
 * Requests an update to be made to the users settings
 */
export const setUserSettingsRequest = (params: { useCustomSectors: boolean }) => ({
  type: ActionTypes.SET_USER_SETTINGS_REQUEST,
  payload: params,
});

export const setUserSettingsSuccess = (params: { [key: string]: any }) => ({
  type: ActionTypes.SET_USER_SETTINGS_SUCCESS,
  payload: params,
});

/**
 * Retreives the current user settings
 */
export const fetchUserSettingsRequest = () => ({
  type: ActionTypes.FETCH_USER_SETTINGS_REQUEST,
});

export const fetchUserSettingsSuccess = (params: { [key: string]: any }) => ({
  type: ActionTypes.FETCH_USER_SETTINGS_SUCCESS,
  payload: params,
});

export const fetchOrganizationUsersRequest = () => ({
  type: ActionTypes.FETCH_ORGANIZATION_USERS_REQUEST,
});

export const fetchOrganizationUsersSuccess = (payload: any) => ({
  type: ActionTypes.FETCH_ORGANIZATION_USERS_SUCCESS,
  payload,
});

export const fetchSharedReportOrganizationUsersRequest = ({ payload }) => ({
  type: ActionTypes.FETCH_SHARED_REPORT_ORGANIZATION_USERS_REQUEST,
  payload,
});

export const fetchSharedReportOrganizationUsersSuccess = (payload: any) => ({
  type: ActionTypes.FETCH_SHARED_REPORT_ORGANIZATION_USERS_SUCCESS,
  payload,
});

type Actions = {
  [ActionTypes.SET_DEVICE_VIEWPORT_DIMENSIONS]: ReturnType<typeof setDeviceViewportDimensions>;
  [ActionTypes.FETCH_USER_SETTINGS_REQUEST]: ReturnType<typeof fetchUserSettingsRequest>;
  [ActionTypes.FETCH_USER_SETTINGS_SUCCESS]: ReturnType<typeof fetchUserSettingsSuccess>;
  [ActionTypes.FETCH_ORGANIZATION_USERS_REQUEST]: ReturnType<typeof fetchOrganizationUsersRequest>;
  [ActionTypes.FETCH_ORGANIZATION_USERS_SUCCESS]: ReturnType<typeof fetchOrganizationUsersSuccess>;
  [ActionTypes.FETCH_SHARED_REPORT_ORGANIZATION_USERS_REQUEST]: ReturnType<
    typeof fetchSharedReportOrganizationUsersRequest
  >;
  [ActionTypes.FETCH_SHARED_REPORT_ORGANIZATION_USERS_SUCCESS]: ReturnType<
    typeof fetchSharedReportOrganizationUsersSuccess
  >;
  [ActionTypes.SET_USER_SETTINGS_REQUEST]: ReturnType<typeof setUserSettingsRequest>;
  [ActionTypes.SET_USER_SETTINGS_SUCCESS]: ReturnType<typeof setUserSettingsSuccess>;
};

/**
 * REDUCERS
 */
export type SharedState = {
  userSettings: UserSettings | null;
  organization: {
    allUsers: User[];
    sharedReportUsers: User[];
  };
  device: {
    viewportDimensions: {
      width: number;
      height: number;
    };
  };
};

export const initialState: SharedState = {
  userSettings: null,
  organization: {
    allUsers: [],
    sharedReportUsers: [],
  },
  device: {
    viewportDimensions: {
      width: getViewportWidth(),
      height: getViewportHeight(),
    },
  },
};

const userSettingsReducer = reduxUtil.createReducer<SharedState['userSettings'], Actions>(
  initialState.userSettings,
  {
    [ActionTypes.SET_USER_SETTINGS_SUCCESS]: (state, action) => {
      return { ...objectSnakeToCamel(action.payload).settings };
    },
    [ActionTypes.FETCH_USER_SETTINGS_SUCCESS]: (state, action) => {
      return { ...objectSnakeToCamel(action.payload).settings };
    },
  }
);

const allOrganizationUsersReducer = reduxUtil.createReducer<
  SharedState['organization']['allUsers'],
  Actions
>(initialState.organization.allUsers, {
  [ActionTypes.FETCH_ORGANIZATION_USERS_SUCCESS]: (state, action) => {
    return action.payload.data;
  },
});

const sharedReportOrganizationUsersReducer = reduxUtil.createReducer<
  SharedState['organization']['sharedReportUsers'],
  Actions
>(initialState.organization.sharedReportUsers, {
  [ActionTypes.FETCH_SHARED_REPORT_ORGANIZATION_USERS_SUCCESS]: (state, action) => {
    return action.payload.data;
  },
});

const organizationReducer = combineReducers({
  allUsers: allOrganizationUsersReducer,
  sharedReportUsers: sharedReportOrganizationUsersReducer,
});

const deviceReducer = reduxUtil.createReducer<SharedState['device'], Actions>(initialState.device, {
  [ActionTypes.SET_DEVICE_VIEWPORT_DIMENSIONS]: (state, action) => ({
    ...state,
    viewportDimensions: {
      width: action.payload.width,
      height: action.payload.height,
    },
  }),
});

export default combineReducers({
  userSettings: userSettingsReducer,
  organization: organizationReducer,
  device: deviceReducer,
});

/**
 * SELECTORS
 */
const selectState = (state: RootState): SharedState => state.shared;
const selectOrganization = state => selectState(state).organization;
export const selectOrganizationUsers = state => selectOrganization(state).allUsers;
export const selectSharedReportOrganizationUsers = state =>
  selectOrganization(state).sharedReportUsers;
export const selectUserSettings = createSelector(selectState, sharedState => {
  return sharedState.userSettings ? sharedState.userSettings : {};
});
export const selectUserSettingsLoaded = createSelector(selectState, sharedState => {
  return !!sharedState.userSettings;
});
export const selectUserSettingUseCustomSectors = state =>
  !!selectUserSettings(state).useCustomSectors;

export function* fetchUserSettingsSaga() {
  const resp = yield call(dlgwApi.fetchUserSettings);
  const canReadCustomSectors = checkPermissions(getUserPermissions(), [
    permissionsByEntity.CustomSectors.READ,
  ]);
  if (resp.ok) {
    // If cant read canReadCustomSectors we need to modify useCustomSectors as being disabled
    const data = canReadCustomSectors
      ? resp.data
      : merge(resp.data, { settings: { useCustomSectors: false } });

    yield put(fetchUserSettingsSuccess(data));
  }
}

export function* setUserSettingsSaga({ payload }: AnyAction) {
  const resp = yield call(dlgwApi.updateUserSettings, { settings: payload });
  if (resp.ok) {
    yield put(setUserSettingsSuccess(resp.data));
  }
}

export function* fetchOrganizationUsersSaga(): SagaIterator {
  const canReadSharedReports = checkPermissions(getUserPermissions(), [
    permissionsByEntity.SharedReports.READ,
  ]);

  if (canReadSharedReports) {
    const requiredPermissions = [permissionsByEntity.SharedReports.READ];

    const resp: ApiResponse<FetchOrganizationUsersResponse> = yield call(fetchOrganizationUsers, {
      requiredPermissions,
    });

    if (resp.ok) {
      yield put(fetchOrganizationUsersSuccess(resp.data));
    }
  }
}

export function* fetchSharedReportOrganizationUsersSaga({ payload }): SagaIterator {
  const useCustomSectors = payload.useCustomSectors;
  const canReadSharedReports = checkPermissions(getUserPermissions(), [
    permissionsByEntity.SharedReports.READ,
  ]);

  if (canReadSharedReports) {
    const requiredPermissions = [permissionsByEntity.SharedReports.READ];
    if (useCustomSectors) {
      requiredPermissions.push(permissionsByEntity.CustomSectors.READ);
    }

    const resp: ApiResponse<FetchOrganizationUsersResponse> = yield call(fetchOrganizationUsers, {
      requiredPermissions,
    });

    if (resp.ok) {
      yield put(fetchSharedReportOrganizationUsersSuccess(resp.data));
    }
  }
}

export function* sharedSaga() {
  yield takeEvery<Actions[ActionTypes.FETCH_USER_SETTINGS_REQUEST]>(
    ActionTypes.FETCH_USER_SETTINGS_REQUEST,
    fetchUserSettingsSaga
  );
  yield takeEvery<Actions[ActionTypes.SET_USER_SETTINGS_REQUEST]>(
    ActionTypes.SET_USER_SETTINGS_REQUEST,
    setUserSettingsSaga
  );
  yield takeEvery<Actions[ActionTypes.FETCH_ORGANIZATION_USERS_REQUEST]>(
    ActionTypes.FETCH_ORGANIZATION_USERS_REQUEST,
    fetchOrganizationUsersSaga
  );
  yield takeEvery<Actions[ActionTypes.FETCH_SHARED_REPORT_ORGANIZATION_USERS_REQUEST]>(
    ActionTypes.FETCH_SHARED_REPORT_ORGANIZATION_USERS_REQUEST,
    fetchSharedReportOrganizationUsersSaga
  );
}
